import dayjs from 'dayjs'
import { MinimalPriceFiltersState } from 'management/components/MinimalPriceRulesManagement/useFilters'
import { FiltersState } from 'management/components/RulesManagement/useFilters'

export const createPricingRulesFilters = (searchTerm: string, filtersState: FiltersState, activeNow: boolean) => {
  const filters = []
  if (searchTerm.trim()) {
    filters.push(searchTerm)
  }
  if (filtersState.allVendors) {
    filters.push(`Vendor.Id==${filtersState.allVendors.id}`)
  }
  if (filtersState.allDeliverySites) {
    filters.push(`DeliverySite.Id==${filtersState.allDeliverySites.id}`)
  }
  if (filtersState.allProductCategories) {
    filters.push(`Category.Id==${filtersState.allProductCategories.id}`)
  }
  if (filtersState.validFrom) {
    filters.push(`ValidFrom==${filtersState.validFrom ? dayjs(filtersState.validFrom).toISOString() : null}`)
  }
  if (filtersState.validTo) {
    filters.push(`ValidTo==${filtersState.validTo ? dayjs(filtersState.validTo).toISOString() : null}`)
  }
  if (activeNow) {
    filters.push('ValidNow')
  }

  return filters.join(',')
}

export const createMinimalPriceRulesFilters = (searchTerm: string, minimalPriceFilterState: MinimalPriceFiltersState) => {
  const filters = []
  if (searchTerm.trim()) {
    filters.push(searchTerm)
  }
  if (minimalPriceFilterState.allVendors) {
    filters.push(`Product.Vendor.Name==${minimalPriceFilterState.allVendors.name}`)
  }

  return filters.join(',')
}
