import { NotificationEventType } from 'core/types'

export const notification = {
  notifications: 'Powiadomienia',
  noNewNotifications: 'Brak nowych powiadomień',
  countNotifications: '{{count}} powiadomień',
  moreThanCountNotifications: 'Więcej niż {{count}} powiadomień',
  [NotificationEventType.OrderAccepted]: {
    title: 'Zamówienie zostało zaakceptowane',
    content: 'Gratulacje! Twoje zamówienie zostało zaakceptowane przez {{vendorName}}.',
  },
  [NotificationEventType.OrderPlaced]: {
    title: 'Dostępne nowe zamówienie od {{deliverySiteName}}',
    content: 'Nowe zamówienie na kwotę {{totalPriceGross}}PLN od {{deliverySiteName}} czeka na realizację.',
  },
  [NotificationEventType.CatalogUpdated]: {
    title: 'Nowy katalog {{vendorName}} został pomyślnie zaimportowany',
  },
  [NotificationEventType.CatalogUpdateFail]: {
    title: 'Import katalogu {{vendorName}} nie powiódł się!',
    content: 'Spróbuj ponownie, jeżeli problem będzie się powtarzał, skontaktuj się z zespołem Skalo.',
  },
  [NotificationEventType.ProductOrdersMatched]: {
    title: 'Przypisanie produktów do magazynu gotowe',
    content:
      'Zakończono przypisywanie produktów z zamówienia {{friendlyOrderNumber}} od dostawcy {{vendorName}}. Po dostarczeniu zamówienia produkty będą gotowe do włożenia do magazynu.',
  },
  [NotificationEventType.ClientCreated]: {
    title: 'Nowe konto klienta',
    content: '{{creatingUser}} utworzył konto dla {{clientName}} o NIPie {{clientTaxId}}. Wymagana weryfikacja!',
  },
  [NotificationEventType.OrderProcessing]: {
    title: 'Zamówienie przyjęte do realizacji',
    content: 'Twoje zamówienie zostało przyjęte do realizacji przez {{vendorName}}.',
  },
  [NotificationEventType.OrderCommented]: {
    title: 'Twoje zamówienie {{friendlyOrderNumber}} zostało skomentowane',
  },
  [NotificationEventType.PosSynchroFinishedSuccess]: {
    title: 'Synchronizacja z "Dotykacka" zakończona sukcesem',
  },
  [NotificationEventType.PosSynchroFinishedFailed]: {
    title: 'Synchronizacja z "Dotykacka" nie powiodła się',
    content: 'Spróbuj ponownie, jeżeli problem będzie się powtarzał, skontaktuj się z zespołem Skalo.',
  },
  [NotificationEventType.AddProductToOrder]: {
    title: '{{productName}} został dodany do Twojego zamówienia {{friendlyOrderNumber}}',
  },
  [NotificationEventType.OrderCancelledByClient]: {
    title: '{{clientName}} anulował zamówienie nr: {{friendlyOrderNumber}}',
  },
}
