import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'
import { useAddClient, useDeleteClient, useDeliverSites, useUpdateClient } from 'companies/hooks'
import { ActionType } from 'core/consts'
import { ClientApiErrorCode } from 'core/consts/errorCodes/client'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { AddClient, ApiErrorRes, Client } from 'core/types'
import { useGetSpingoLimit } from 'items/hooks'

import AddEditClientDialog from './AddEditClientDialog'
import ClientsTable from './ClientsTable'
import ConfirmDeleteClientDialog from './ConfirmDeleteClientDialog'
import SpingoLimitDialog from './SpingoLimitDialog'

enum ClientManagementDialogActionType {
  CHECK_SPINGO = 'check-spingo',
}

type ClientManagementDialog = {
  client: Client | null
  actionType: ActionType | ClientManagementDialogActionType
}

interface ClientManagementProps {
  clients: Client[]
  refetchClients: () => void
}

const ClientManagement = ({ clients, refetchClients }: ClientManagementProps) => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState<ClientManagementDialog | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [clientToDelete, setClientToDelete] = useState<Client | null>(null)

  const { addClient, isLoading: isAdding } = useAddClient()
  const { updateClient, isLoading: isUpdating } = useUpdateClient()
  // check spingo limit if payment is available
  const { spingoLimitData, spingoLimitIsFetching } = useGetSpingoLimit(
    dialogOpen?.client?.id ?? '',
    dialogOpen?.actionType === ClientManagementDialogActionType.CHECK_SPINGO,
  )
  const { deleteClient } = useDeleteClient(clientToDelete?.id ?? '')
  const { refetchDeliverySites } = useDeliverSites()

  const handleOpenDialog = (actionType: ActionType | ClientManagementDialogActionType, client?: Client) => {
    setDialogOpen({ actionType, client: client ?? null })
  }

  const handleCloseDialog = () => {
    setDialogOpen(null)
  }

  const handleAddClient = async (newClient: AddClient) => {
    await addClient(newClient, {
      onSuccess: () => {
        handleCloseDialog()
        refetchClients()
        refetchDeliverySites()
        snackbar.success(t('company.addCompany.success'))
      },
      onError: (error: AxiosError<ApiErrorRes>) => {
        const errorCode = error.response?.data?.code
        switch (errorCode) {
          case ClientApiErrorCode.TAX_ID_TAKEN:
            snackbar.error(t('clients.errors.taxIdTaken'))
            break
          default:
            snackbar.error(t('company.updateCompany.error'))
        }
      },
    })
  }

  const handleUpdateClient = async (updatedClient: Client) => {
    await updateClient(updatedClient, {
      onSuccess: () => {
        handleCloseDialog()
        refetchClients()
        snackbar.success(t('company.updateCompany.success'))
      },
      onError: () => {
        snackbar.error(t('company.updateCompany.error'))
      },
    })
  }

  const handleDeleteClient = (client: Client) => {
    setClientToDelete(client)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = async () => {
    await deleteClient(
      {},
      {
        onSuccess: () => {
          setClientToDelete(null)
          setDeleteDialogOpen(false)
          refetchClients()
        },
        onError: () => {
          snackbar.error(t('common.errors.unexpected.subTitle'))
        },
      },
    )
  }

  return (
    <>
      <ClientsTable
        clients={clients}
        onAddButtonClicked={() => handleOpenDialog(ActionType.ADD)}
        onEdit={client => handleOpenDialog(ActionType.EDIT, client)}
        onCheckSpingo={client => handleOpenDialog(ClientManagementDialogActionType.CHECK_SPINGO, client)}
        onDelete={handleDeleteClient}
        isLoading={spingoLimitIsFetching}
      />
      {dialogOpen?.actionType === ActionType.ADD && (
        <AddEditClientDialog onClose={handleCloseDialog} onAction={handleAddClient} processing={isAdding} clients={clients} />
      )}
      {dialogOpen?.actionType === ActionType.EDIT && (
        <AddEditClientDialog
          onClose={handleCloseDialog}
          onAction={handleUpdateClient}
          processing={isAdding || isUpdating}
          clients={clients}
          client={dialogOpen?.client}
        />
      )}
      <ConfirmDeleteClientDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        client={clientToDelete}
      />
      {!spingoLimitIsFetching && dialogOpen?.actionType === ClientManagementDialogActionType.CHECK_SPINGO && (
        <SpingoLimitDialog onClose={handleCloseDialog} paymentLimit={spingoLimitData} client={dialogOpen?.client} />
      )}
    </>
  )
}

export default ClientManagement
