import { Chip, Typography } from '@mui/material'
import { AvatarImg } from 'core/components'
import { UnsuccessfulItem } from 'core/types'
import { TFunction } from 'i18next'
import noImage from 'items/images/noImage.png'

export const mapUnsuccessfulItemsDialogData = (data: UnsuccessfulItem[], t: TFunction) => {
  const mappedData = data.map(el => {
    return {
      rowId: el.productId,
      data: {
        primaryImageUri: <AvatarImg imgUrl={el.primaryImageUri ? el.primaryImageUri : null} img={noImage} name={el.productName} />,
        productName: el.productName,
        availableStock:
          el.availableStock > 0 ? el.availableStock : <Chip color="error" variant="outlined" label={t('orders.outOfStock')} />,
        actualAndExpected:
          el.availableStock > 0 ? (
            `${el.actualQuantity} / ${el.expectedQuantity}`
          ) : (
            <Typography color="error">
              {el.actualQuantity} / {el.expectedQuantity}
            </Typography>
          ),
        basketCount: el.availableStock,
      },
    }
  })

  return mappedData
}

export const mapUnsuccessfulItemsDialogColumns = (t: TFunction) => [
  { title: '', id: 'primaryImageUri' },
  { title: t('shoppingList.name'), id: 'productName' },
  { title: t('shoppingList.available'), id: 'availableStock' },
  { title: t('shoppingList.actualAndExpected'), id: 'actualAndExpected' },
  { title: t('shoppingList.basketCount'), id: 'basketCount' },
]
