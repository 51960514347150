import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { Order, ProductOrder } from 'core/types'
import { useChangeOrderStatus, useDeleteProductOrder, useSwapProductOrder, useUpdateProductOrder } from 'vendor/hooks'
import { DialogTypes, SwapProductOrder, UpdateSingleProductOrder } from 'vendor/types/types'

import AddProductDialog from './AddProductDialog'
import { ChangeOrderStatusDialog } from './ChangeOrderStatusDialog'
import DeleteProductDialog from './DeleteProductDialog'
import EditOrderDetailDialog from './EditOrderDetailDialog'
import SwapOrderDetailDialog from './SwapOrderDetailDialog'

type VendorOrderDetailsDialogProps = {
  onClose: () => void
  openDialog: DialogTypes | null
  order: Order
  refetchOrder: () => void
  selectedProduct: ProductOrder | null
}

export const VendorOrderDetailsDialogs = ({ onClose, openDialog, order, refetchOrder, selectedProduct }: VendorOrderDetailsDialogProps) => {
  const { uuid: orderId } = useParams()
  const { t } = useTranslation()
  const [selectedStatus, setSelectedStatus] = useState(order?.status)
  const { changeStatus } = useChangeOrderStatus(selectedStatus, orderId)

  const { updateProductOrder, isLoading: isUpdatingProduct } = useUpdateProductOrder()
  const { swapProductOrder, isLoading: isSwappingProduct } = useSwapProductOrder()
  const { deleteProductFromOrder, isLoading: isDeletingProduct } = useDeleteProductOrder()

  const snackbar = useSnackbar()

  const handleChangeStatus = (newStatus: string) => {
    setSelectedStatus(newStatus)
  }

  const handleCloseStatusDialog = () => {
    onClose()
    setSelectedStatus(order?.status)
  }

  const handleUpdateOrderStatus = async () => {
    try {
      await changeStatus(selectedStatus, orderId)
      handleCloseStatusDialog()
      snackbar.success(t('order.changeStatusSuccess'))
    } catch (error) {
      snackbar.error(t('order.changeStatusError'))
    }
  }

  const handleUpdateProduct = (updateOrder: UpdateSingleProductOrder) => {
    updateProductOrder(updateOrder, {
      onSuccess: () => {
        onClose()
        snackbar.success(t('vendors.update.success'))
      },
      onError: () => {
        snackbar.error(t('vendors.update.error'))
      },
    })
  }

  const handleSwapProduct = (swapProduct: SwapProductOrder) => {
    swapProductOrder(swapProduct, {
      onSuccess: () => {
        onClose()
        snackbar.success(t('vendors.update.success'))
      },
      onError: () => {
        snackbar.error(t('vendors.update.error'))
      },
    })
  }

  const handleDeleteProduct = () => {
    deleteProductFromOrder(
      {
        id: selectedProduct?.id,
        orderId,
      },
      {
        onSuccess: () => {
          onClose()
          snackbar.success(t('vendors.update.success'))
        },
        onError: () => {
          snackbar.error(t('vendors.update.error'))
        },
      },
    )
  }

  return (
    <>
      {openDialog === DialogTypes.CHANGE_ORDER_STATUS && (
        <ChangeOrderStatusDialog
          onClose={handleCloseStatusDialog}
          onSave={handleUpdateOrderStatus}
          changeStatus={handleChangeStatus}
          selectedStatus={selectedStatus}
        />
      )}

      {openDialog === DialogTypes.EDIT_PRODUCT && (
        <EditOrderDetailDialog
          onClose={onClose}
          onSave={handleUpdateProduct}
          orderDetail={selectedProduct!}
          processing={isUpdatingProduct}
        />
      )}

      {openDialog === DialogTypes.SWAP_PRODUCT && (
        <SwapOrderDetailDialog
          onClose={onClose}
          onSave={handleSwapProduct}
          orderDetail={selectedProduct!}
          processing={isSwappingProduct}
          vendorId={order.vendor.id}
        />
      )}
      {openDialog === DialogTypes.DELETE_PRODUCT && (
        <DeleteProductDialog
          onClose={onClose}
          onSave={handleDeleteProduct}
          processing={isDeletingProduct}
          selectedProductName={selectedProduct?.productName ?? ''}
        />
      )}
      {openDialog === DialogTypes.ADD_PRODUCT && (
        <AddProductDialog onClose={onClose} orderId={orderId ?? ''} vendorId={order.vendor.id} refetchOrder={refetchOrder} />
      )}
    </>
  )
}
