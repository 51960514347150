import React from 'react'

import { Grid } from '@mui/material'
import { getUserRoles } from 'auth/contexts/AuthProvider'
import { UserRole } from 'core/consts'
import AchievementWidgetVendor from 'vendor/widgets/AchievementWidgetVendor'
import MeetingWidgetsVendor from 'vendor/widgets/MeetingWidgetsVendor'
import PersonalTargetsWidgetVendor from 'vendor/widgets/PersonalTargetWidgetVendro'
import ViewsWidgetVendor from 'vendor/widgets/ViewsWidgetVendor'

import AchievementWidget from '../widgets/AchievementWidget'
import MeetingWidgets from '../widgets/MeetingWidgets'
import PersonalTargetsWidget from '../widgets/PersonalTargetsWidget'
import ViewsWidget from '../widgets/ViewsWidget'
import WelcomeWidget from '../widgets/WelcomeWidget'

const Home = () => {
  const roles = getUserRoles()
  const isAdmin = roles?.includes(UserRole.ADMIN)
  const isCompanyOwner = roles?.includes(UserRole.COMPANY_ADMIN)
  const isTestVendor = roles?.includes(UserRole.TEST_VENDOR)

  return (
    <React.Fragment>
      {isCompanyOwner && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WelcomeWidget />
            <PersonalTargetsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AchievementWidget />
            <ViewsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MeetingWidgets />
          </Grid>
        </Grid>
      )}
      {isAdmin && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WelcomeWidget />
            <PersonalTargetsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AchievementWidget />
            <ViewsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MeetingWidgets />
          </Grid>
        </Grid>
      )}
      {isTestVendor && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WelcomeWidget />
            <PersonalTargetsWidgetVendor />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AchievementWidgetVendor />
            <ViewsWidgetVendor />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MeetingWidgetsVendor />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default Home
