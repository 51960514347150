export enum PlatformManagementTab {
  PRICE_RULES = 'price-rules',
  MINIMAL_PRICE = 'minimal-price',
}

export enum VendorManagementTab {
  ADD_VENDOR = 'add-vendor',
  MAIN_DATA = 'main-data',
  CLIENT_PAY_METHODS = 'client-pay-methods',
  DISABLE_DSITE = 'disable-delivery-site',
  CATALOG_UPLOAD = 'catalog-upload',
}
