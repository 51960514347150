import { OrderStatus } from 'core/consts'

type StatusDisplayProperty = {
  color: string
  messageKey: string
}

type StatusDisplayProperties = {
  [key: string]: StatusDisplayProperty
}

const statusDisplayProperties: StatusDisplayProperties = {
  [OrderStatus.Placed]: { color: 'info.main', messageKey: 'order.status.placed' },
  [OrderStatus.Paid]: { color: 'success.light', messageKey: 'order.status.paid' },
  [OrderStatus.FailedPayment]: { color: 'error.main', messageKey: 'order.status.failedPayment' },
  [OrderStatus.Processing]: { color: 'info.dark', messageKey: 'order.status.processing' },
  [OrderStatus.Accepted]: { color: 'success.main', messageKey: 'order.status.accepted' },
  // [OrderStatus.AcceptedWithComment]: { color: 'success.dark', messageKey: 'order.status.acceptedWithComment' },
  [OrderStatus.Canceled]: { color: 'grey.700', messageKey: 'order.status.canceled' },
  [OrderStatus.ReadyForShipment]: { color: 'warning.main', messageKey: 'order.status.readyForShipment' },
  [OrderStatus.Shipped]: { color: 'info.main', messageKey: 'order.status.shipped' },
  [OrderStatus.Delivered]: { color: 'teal', messageKey: 'order.status.delivered' },
  [OrderStatus.DeliveryIssue]: { color: 'warning.main', messageKey: 'order.status.deliveryIssue' },
  [OrderStatus.Returned]: { color: 'grey.500', messageKey: 'order.status.returned' },
  [OrderStatus.PartlyRefunded]: { color: 'grey.500', messageKey: 'order.status.partlyRefunded' },
  [OrderStatus.Refunded]: { color: 'grey.500', messageKey: 'order.status.refunded' },
  [OrderStatus.AcceptedWithModification]: { color: 'success.dark', messageKey: 'order.status.acceptedWithModification' },
  [OrderStatus.Archived]: { color: 'grey.700', messageKey: 'order.status.archived' },
}

export { statusDisplayProperties }
export type { StatusDisplayProperties, StatusDisplayProperty }
