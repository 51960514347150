import { memo } from 'react'

import { Typography, TypographyVariant } from '@mui/material'
import { formatPhoneNumber } from 'core/utils'

export interface PhoneNumberProps {
  phoneCode: string
  phoneNo?: number | string
  variant?: TypographyVariant
  color?: string
}

const PhoneNumber = memo(({ phoneCode, phoneNo, variant = 'subtitle1', color = 'text.primary' }: PhoneNumberProps) => {
  return (
    <Typography variant={variant} color={color}>
      {phoneNo ? (
        <>
          <Typography component="span" variant={variant} color="text.secondary" sx={{ pr: 0.5 }}>
            {phoneCode}
          </Typography>
          {formatPhoneNumber(phoneNo.toString(), ' ')}
        </>
      ) : (
        '-'
      )}
    </Typography>
  )
})

export default PhoneNumber
