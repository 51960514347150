import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Box } from '@mui/material'
import { MainPaper, MainTable, NotReadyWrapper, Select } from 'core/components'
import { DeliverySite } from 'core/types'
import { TFunction } from 'i18next'
import { mapAdministratorPosProducts, mapAdministratorPosProductsColumns } from 'management/helpers/mapAdministratorPosProducts'
import { useGetDotykackaPosProducts } from 'management/hooks'

interface AdministratorPosProductsProps {
  selectedDeliverySite: DeliverySite | null
}

export const AdministratorPosProducts: React.FC<AdministratorPosProductsProps> = ({ selectedDeliverySite }) => {
  const { t } = useTranslation()
  const sortOptions = (t: TFunction) => [
    {
      id: '-quantity',
      name: t('administrator.quantityAsc'),
      icon: <ArrowUpward />,
    },
    {
      id: 'quantity',
      name: t('administrator.quantityDesc'),
      icon: <ArrowDownward />,
    },
  ]

  const [sort, setSort] = useState('-quantity')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { dotykackaWarehouse, isLoading, isError, isSuccess } = useGetDotykackaPosProducts({
    Filters: selectedDeliverySite ? `DeliverySiteId==${selectedDeliverySite.id}` : '',
    Sorts: sort,
    Page: page + 1,
    PageSize: rowsPerPage,
  })

  useEffect(() => {
    setPage(0)
  }, [selectedDeliverySite])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setRowsPerPage(newPageSize)
  }

  return (
    <>
      <Box>
        <Select
          fullWidth
          name="sort"
          label={t('orders.sortTitle')}
          value={sort}
          onChange={e => setSort(e.target.value)}
          options={sortOptions(t)}
        />
      </Box>
      <NotReadyWrapper isLoading={isLoading} isError={isError} isSuccess={isSuccess}>
        <MainPaper sx={{ mt: 2 }}>
          <MainTable
            columns={mapAdministratorPosProductsColumns(t)}
            rows={mapAdministratorPosProducts(dotykackaWarehouse ?? { totalCount: 0, items: [] })}
            count={dotykackaWarehouse?.totalCount}
            pagination={{
              page: page,
              onPageChange: handlePageChange,
              pageSize: rowsPerPage,
              onPageSizeChange: handlePageSizeChange,
            }}
          />
        </MainPaper>
      </NotReadyWrapper>
    </>
  )
}
