import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { KeyboardDoubleArrowRight, ShoppingBasket } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material'
import CompanySelector from 'companies/components/CompanySelector'
import { useCompanyContext } from 'companies/contexts/CompanyContext'
import { Loader, PaperTitle, Select } from 'core/components'
import { useBasicPosSalesReport } from 'core/hooks/api'
import { BasicPosSalesReport } from 'core/types'
import { getDaysAgoList } from 'core/utils'

export const DotykackaReport = () => {
  const { t, i18n } = useTranslation()
  const { selectedCompany } = useCompanyContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dateFromList = useMemo(
    () => [getDaysAgoList(1, t), getDaysAgoList(3, t), getDaysAgoList(7, t), getDaysAgoList(30, t)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  const [dateFrom, setDateFrom] = useState(dateFromList[0].id)

  const { posSalesReportData, posSalesReportsLoading, posSalesReportsIsSuccess, posSalesReportsIsError } = useBasicPosSalesReport({
    deliverySiteId: Number(selectedCompany?.id),
    from: dateFrom,
  })
  const soldProductsList = posSalesReportData && posSalesReportData?.length > 0 ? posSalesReportData : []

  useEffect(() => {
    setDateFrom(dateFromList[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <Box>
      <PaperTitle
        title={t('pos.recentlySold')}
        rightEl={
          <Box display="flex" alignItems="center" gap={1}>
            <CompanySelector showError={false} setError={() => {}} />
            <Select
              label={t('common.dateRange')}
              name="dateFrom"
              value={dateFrom}
              onChange={e => setDateFrom(e.target.value)}
              options={dateFromList}
              sx={{ width: { xs: '100%', sm: '10rem' } }}
            />
          </Box>
        }
      />

      {selectedCompany ? (
        <Box position="relative" sx={{ minHeight: 80 }}>
          {posSalesReportsLoading && <Loader relative />}
          {posSalesReportsIsError && (
            <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
              {t('common.errors.unexpected.subTitle')}
            </Typography>
          )}
          {posSalesReportsIsSuccess && (
            <>
              {soldProductsList.length > 0 ? (
                <Box>
                  {/* header */}
                  <Grid container spacing={2} mb={3}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.productName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.quantity')}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('orders.ingredients')}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* products and ingredients list */}
                  <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
                    {soldProductsList.map((el, idx) => (
                      <Grid container spacing={2} alignItems="baseline" key={el.productName + idx} mb={2}>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">{el.productName}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {el.quantity} {t('common.unit.pcs')}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} display="flex" alignItems="center" flexWrap="wrap" gap={0.5}>
                          {el.ingredients.length > 0 ? (
                            el.ingredients.map((ing, iidx) => (
                              <Chip
                                key={ing + iidx}
                                label={ing}
                                component={RouterLink}
                                to={`/catalog?search=${encodeURIComponent(ing)}`}
                                clickable
                              />
                            ))
                          ) : (
                            <Typography variant="body2">-</Typography>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Box>

                  {/* summary */}
                  <SoldSummary soldProductsList={soldProductsList} />
                </Box>
              ) : (
                // products not found for selected deliverySiteId and date range
                <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                  <Typography>{t('pos.notFoundDotykackaData')}</Typography>
                  <Button component={RouterLink} to="/restaurants" endIcon={<KeyboardDoubleArrowRight />}>
                    {t('common.integrateDotykacka')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      ) : (
        // deliverySiteId not selected
        <Typography variant="body2" color="text.secondary">
          {t('pos.selectCompanyToSeeWhatYouSold')}
        </Typography>
      )}
    </Box>
  )
}

const SoldSummary = ({ soldProductsList }: { soldProductsList: BasicPosSalesReport[] }) => {
  const { t } = useTranslation()
  return (
    <>
      <Divider sx={{ mt: 1 }} />
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1} mt={2}>
        <Typography color="info.dark">
          <Trans
            i18nKey="pos.posSoldItemsInfo"
            values={{
              val: soldProductsList
                .slice(0, 5)
                .map(el => el.productName)
                .join(', '),
            }}
          />
        </Typography>
        <Button component={RouterLink} variant="outlined" startIcon={<ShoppingBasket />} to="/catalog">
          {t('admin.drawer.menu.orders')}
        </Button>
      </Box>
    </>
  )
}
