export enum NotificationEventType {
  OrderAccepted = 'OrderAccepted',
  OrderPlaced = 'OrderPlaced',
  CatalogUpdated = 'CatalogUpdated',
  CatalogUpdateFail = 'CatalogUpdateFail',
  ProductOrdersMatched = 'ProductOrdersMatched',
  ClientCreated = 'ClientCreated',
  OrderProcessing = 'OrderProcessing',
  OrderCommented = 'OrderCommented',
  PosSynchroFinishedSuccess = 'PosSynchroFinishedSuccess',
  PosSynchroFinishedFailed = 'PosSynchroFinishedFailed',
  AddProductToOrder = 'AddProductToOrder',
  OrderCancelledByClient = 'OrderCancelledByClient',
}
export enum NotificationEventFnType {
  OrderAccepted = 'onOrderAccepted',
  OrderPlaced = 'onOrderPlaced',
  CatalogUpdated = 'onCatalogUpdated',
  CatalogUpdateFail = 'onCatalogUpdateFail',
  ProductOrdersMatched = 'onProductOrdersMatched',
  ClientCreated = 'onClientCreated',
  OrderProcessing = 'onOrderProcessing',
  OrderCommented = 'onOrderCommented',
  PosSynchroFinishedSuccess = 'onPosSynchroFinishedSuccess',
  PosSynchroFinishedFailed = 'onPosSynchroFinishedFailed',
  AddProductToOrder = 'onAddProductToOrder',
  OrderCancelledByClient = 'onOrderCancelledByClient',
}

export enum NotificationEventVariant {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  ORDER = 'order',
  SYSTEM = 'system',
  SUCCESS = 'success',
}

export type NotificationItem = {
  eventKey: NotificationEventType
  variant: NotificationEventVariant
  title: string
  subtitle?: string
  content?: string
  orderId?: string
  context?: string
}

export type NotificationEventData = {
  id: string
  userId: string
  type: NotificationEventType
  data: any
  timestamp: string
  read: boolean
}

export type NotificationEvent = {
  id: string
  type: NotificationEventType
  data: object
  timestamp: string
  read: boolean
} & NotificationItem

export type NotificationEventFn = {
  [key in NotificationEventFnType]: (...val: any) => void
}
