import { SxProps } from '@mui/material'

export interface SelectItem {
  value: string
  label: string
  disabled?: boolean
}

export interface SelectOption {
  label?: React.ReactNode
  id: string | number
  name?: React.ReactNode
  disabled?: boolean
  styles?: SxProps
  icon?: React.ReactNode | JSX.Element
  iconPosition?: 'start' | 'end'
  otherData?: any
}

export enum SelectType {
  DEFAULT = 'default',
  CHIP = 'chip',
}
