import React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { TitleWithName } from 'core/components'
import { DeliverySite } from 'core/types'

interface ConfirmDeleteDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (siteId: number) => void
  site: DeliverySite | null
}

const ConfirmDeleteDeliverySiteDialog: React.FC<ConfirmDeleteDialogProps> = ({ open, onClose, onConfirm, site }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <TitleWithName title="confirmDeleteDialog.title" value={site?.name} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('confirmDeleteDialog.message')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />}>
          {t('confirmDeleteDialog.cancel')}
        </Button>
        <Button onClick={() => site && onConfirm(site.id)} variant="contained" color="error">
          {t('confirmDeleteDialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteDeliverySiteDialog
