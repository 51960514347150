import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, SxProps, Tab, Tabs, TabsProps, Theme } from '@mui/material'

export interface MainTabsProps extends TabsProps {
  tabs: {
    value: string
    label: string
    icon?: any
    to?: string
  }[]
  onClose?: () => void
  selected: string
  onChangeSelected: (val: string) => void
  children: ReactNode
  sx?: SxProps<Theme>
}

const MainTabs = ({ tabs, onClose, selected, onChangeSelected, sx = [], children, ...others }: MainTabsProps) => {
  const handleChangeTabs = (_event: React.SyntheticEvent, newValue: string) => onChangeSelected(newValue)
  return (
    <>
      <Box sx={[{ borderBottom: 3, borderColor: 'divider', mb: 3 }, ...(Array.isArray(sx) ? sx : [sx])]}>
        <Tabs value={selected} onChange={handleChangeTabs} aria-label="basic tabs" variant="scrollable" {...others}>
          {tabs.map(el => (
            <Tab
              key={el.label}
              iconPosition="end"
              id={`main-tab-${el.value}`}
              sx={{ fontSize: '1rem' }}
              component={el.to ? RouterLink : 'div'}
              to={el.to}
              {...el}
            />
          ))}
        </Tabs>
        {typeof onClose === 'function' && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {children}
    </>
  )
}

export interface MainTabPanelProps {
  children?: ReactNode
  value: string
  selectedValue: string
  sx?: SxProps<Theme>
}

export function MainTabPanel(props: MainTabPanelProps) {
  const { children, selectedValue, value, sx, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={selectedValue !== value}
      id={`main-tabpanel-${value}`}
      aria-labelledby={`main-tab-${value}`}
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
      {...other}
    >
      {selectedValue === value && children}
    </Box>
  )
}

export default MainTabs
