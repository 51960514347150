import { SxProps, Theme } from '@mui/material'

export const boxStyle: SxProps<Theme> = {
  '& > *:not(:last-child)': { mb: 2 },
}

export const catalogFiltersContainerStyle: SxProps<Theme> = {
  top: 100,
  mr: 3,
  mb: { xs: 2, md: 0 },
  width: { xs: '100%', md: '18rem' },
  flex: { xs: 1, md: '0 0 18rem' },
}
