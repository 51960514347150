export enum OrderStatus {
  Placed = 'Placed',
  Processing = 'Processing',
  Accepted = 'Accepted',
  // AcceptedWithComment = 'AcceptedWithComment',
  AcceptedWithModification = 'AcceptedWithModification',
  Paid = 'Paid',
  ReadyForShipment = 'ReadyForShipment',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  DeliveryIssue = 'DeliveryIssue',
  Returned = 'Returned',
  FailedPayment = 'FailedPayment',
  Refunded = 'Refunded',
  PartlyRefunded = 'PartlyRefunded',
  Canceled = 'Canceled',
  Archived = 'Archived',
}
