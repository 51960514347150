import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { ExpandLess, ExpandMore, ManageAccounts } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { Collapse, Divider, IconButton, ListItemIcon, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'auth/contexts/AuthProvider'
import { MenuRoutes } from 'config/menuRoutes'
import Logo from 'core/components/Logo'
import { drawerCollapsedWidth, drawerWidth } from 'core/config/layout'
import { UserRole, UserRoute } from 'core/consts'

import { menuItemStyle } from './styles'

type DrawerProps = {
  items: MenuRoutes[]
  collapsed: boolean
  mobileOpen: boolean
  onDrawerToggle: () => void
  onSettingsToggle: () => void
}

const Drawer = ({ items, collapsed, mobileOpen, onDrawerToggle, onSettingsToggle }: DrawerProps) => {
  const queryClient = useQueryClient()
  const { userInfo, logout } = useAuth()
  const { t } = useTranslation()
  const roles = userInfo?.roles as UserRole[]

  const theme = useTheme()
  const matchesLGDown = useMediaQuery(theme.breakpoints.down('lg'))

  const width = collapsed ? drawerCollapsedWidth : drawerWidth

  const [expandSubMenu, setExpandSubMenu] = useState<string[]>([])

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    queryClient.clear()
    logout()
  }

  const changeExpandSubMenu = (key: string) => {
    setExpandSubMenu(prev => {
      const isExist = prev.find(el => el === key)
      if (isExist) {
        return prev.filter(a => a !== key)
      } else return [...prev, key]
    })
  }

  return (
    <MuiDrawer
      variant={matchesLGDown ? 'temporary' : 'permanent'}
      anchor="left"
      open={mobileOpen}
      onClose={onDrawerToggle}
      ModalProps={{
        keepMounted: matchesLGDown, // Better open performance on mobile.
      }}
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: 1,
          borderColor: 'grey.300',
        },
      }}
    >
      <Box>
        <NavLink to={UserRoute.HOME}>
          <Logo
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
            }}
            size={110}
          />
        </NavLink>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" boxShadow={1}>
        <List>
          {items.map(el => (
            <Fragment key={'key' + el.to}>
              {roles?.some(item => el.roles.includes(item)) && (
                <>
                  {el.subRoutes && !el.to ? (
                    <>
                      <ListItem sx={{ pb: 0.5, mb: -1 }}>
                        <ListItemButton onClick={() => changeExpandSubMenu(el.expandKey as string)} sx={menuItemStyle}>
                          <ListItemIcon>{el.icon}</ListItemIcon>
                          <ListItemText primary={el.title} />
                          {expandSubMenu.find(a => a === el.expandKey) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={Boolean(expandSubMenu.find(a => a === el.expandKey))} timeout="auto" unmountOnExit>
                        <List component="div">
                          {el.subRoutes.map(subRoute => (
                            <ListItem key={subRoute.to} sx={{ pb: 0, pl: 4, mb: -1 }}>
                              <ListItemButton component={NavLink} to={subRoute.to} sx={menuItemStyle}>
                                <ListItemText primary={subRoute.title} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <ListItem sx={{ pb: 0.5, mb: -0.5 }}>
                      <ListItemButton component={NavLink} to={el.to ?? ''} sx={menuItemStyle}>
                        <ListItemIcon>{el.icon}</ListItemIcon>
                        <ListItemText primary={el.title} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </List>
        <List sx={{ mb: 3 }}>
          <ListItem sx={{ pb: 0.5, mb: -0.5 }}>
            <Divider sx={{ width: '100%' }} />
          </ListItem>
          <ListItem sx={{ pb: 0.5, mb: -0.5 }}>
            <ListItemButton component={NavLink} to="/profile" sx={menuItemStyle}>
              <ListItemIcon>
                <ManageAccounts />
              </ListItemIcon>
              {userInfo && (
                <>
                  <ListItemText
                    primary={`${userInfo.firstName} ${userInfo.lastName}`}
                    sx={{
                      display: collapsed ? 'none' : 'block',
                      borderRight: 1,
                      mr: 1,
                    }}
                  />
                  <IconButton onClick={handleLogout}>
                    <LogoutIcon />
                  </IconButton>
                </>
              )}
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ pb: 0.5 }}>
            <ListItemButton onClick={onSettingsToggle} sx={menuItemStyle}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t('admin.drawer.menu.settings')} sx={{ display: collapsed ? 'none' : 'block' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </MuiDrawer>
  )
}

export default Drawer
