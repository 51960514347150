import React, { MouseEvent, memo, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { SelectedTableItem, SelectedTableItemAction, TableAlert, TableRowType } from 'core/types'

import Loader from '../Loader'
import SelectedItemsActions from './SelectedItemsActions'
import { noDataStyle } from './styles'

export interface RowAction {
  icon?: React.ReactElement
  title: string
  callback: (el: any, e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  acceptFiles?: string
  img?: any
  component?: React.ReactElement
  separator?: boolean
}

interface RowWithActions extends TableRowType {
  actions?: RowAction[]
  styles?: any
}
export interface MainTableProps {
  columns: { title: string; id: string }[]
  rows?: RowWithActions[]
  cellStyles?: {
    id: string
    styles: any
  }[]
  count?: number
  pagination?: {
    page: number
    onPageChange: (newPage: number) => void
    pageSize: number
    onPageSizeChange: (newPageSize: number) => void
  }
  loading?: boolean
  checkboxSelection?: boolean
  checkboxSelectionActions?: SelectedTableItemAction[]
  alerts?: TableAlert[]
  error?: boolean
  errorMsg?: string
  noDataMsg?: React.ReactNode
}

const MainTable = memo(
  ({
    columns,
    rows,
    count = 0,
    pagination,
    loading,
    checkboxSelection,
    checkboxSelectionActions = [],
    cellStyles = [],
    alerts = [],
    error,
    errorMsg,
    noDataMsg,
  }: MainTableProps) => {
    const { t } = useTranslation()
    const { width, ref } = useResizeDetector({
      handleHeight: false,
    })

    const [selectedItems, setSelectedItems] = useState<SelectedTableItem[]>([])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      pagination?.onPageSizeChange(parseInt(event.target.value, 10))
      pagination?.onPageChange(0)
    }

    const getCellStyles = useCallback(
      (key: string) => {
        const sx = cellStyles.find(el => el.id === key)?.styles
        return sx ?? {}
      },
      [cellStyles],
    )

    const handleCheckSelected = ({ rowId, data, additionalData }: RowWithActions) => {
      setSelectedItems(prev => {
        const exists = prev.find(el => el.rowId === rowId)
        if (exists) {
          return prev.filter(el => el.rowId !== rowId)
        } else {
          return [...prev, { rowId, data, additionalData }]
        }
      })
    }
    const handleCheckSelectAll = () => {
      setSelectedItems(prev => {
        if (prev.length !== rows?.length) {
          const all = rows?.map(el => ({ rowId: el.rowId, data: el.data, additionalData: el.additionalData })) ?? []
          return [...all]
        } else {
          return []
        }
      })
    }

    useEffect(() => {
      if (!loading && checkboxSelection) {
        setSelectedItems([])
      }
    }, [loading, checkboxSelection])

    return (
      <Box ref={ref} sx={{ position: 'relative' }}>
        <SelectedItemsActions selectedItems={selectedItems} checkboxSelectionActions={checkboxSelectionActions} alerts={alerts} />
        <TableContainer sx={{ width: width ? width - 32 : '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                {checkboxSelection && !loading && rows && rows.length > 0 && !checkboxSelectionActions.every(el => el.hidden) && (
                  <TableCell>
                    <IconButton color="primary" onClick={handleCheckSelectAll}>
                      {selectedItems.length === rows?.length ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </IconButton>
                  </TableCell>
                )}
                {columns.map(el => (
                  <TableCell key={el.id} {...el}>
                    {el.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: 'relative' }}>
              {loading && (
                <>
                  <TableRow>
                    <TableCell sx={{ p: 0 }}>
                      <Loader relative />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: rows && rows.length > 0 ? 0 : 9 }}></TableCell>
                  </TableRow>
                </>
              )}
              {((!loading && rows && rows.length === 0) || error) && (
                <TableRow>
                  <TableCell sx={{ py: 8 }}>
                    <Typography component="div" color={error ? 'error' : 'inherit'} sx={noDataStyle}>
                      {error ? errorMsg ?? t('common.errors.unexpected.subTitle') : noDataMsg ?? t('filters.noDataFound')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {rows?.map(row => (
                <TableRow
                  key={row.rowId}
                  sx={{ ...row.styles, ...(selectedItems.find(el => el.rowId === row.rowId) && { bgcolor: 'grey.50' }) }}
                >
                  {checkboxSelection && !checkboxSelectionActions.every(el => el.hidden) && (
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleCheckSelected(row)}>
                        {selectedItems.find(el => el.rowId === row.rowId) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                      </IconButton>
                    </TableCell>
                  )}
                  {Object.entries(row.data).map(([key, val]) => (
                    <TableCell key={key} sx={getCellStyles(key)}>
                      {val ?? '-'}
                    </TableCell>
                  ))}
                  {row.actions && row.actions?.length > 0 && (
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      {row.actions.map((action, idx) => {
                        // const Icon = action.icon
                        const { title, callback } = action
                        return (
                          <Box display="inline-flex" alignItems="baseline" key={title}>
                            {action.separator && (
                              <Box sx={{ width: '1px', height: 40, bgcolor: 'grey.200', ml: 1.5, mr: 0.5, alignSelf: 'center' }}></Box>
                            )}

                            <Tooltip title={title}>
                              <span>
                                {action.acceptFiles ? (
                                  <IconButton
                                    disabled={action.disabled}
                                    sx={{ ml: idx === 0 ? 0 : 1 }}
                                    role={undefined}
                                    tabIndex={-1}
                                    component="label"
                                  >
                                    {action.icon && action.icon}
                                    {action.img && <img src={action.img} alt={action.title} />}
                                    <input
                                      name="filUpload"
                                      type="file"
                                      hidden
                                      // TODO fix event type
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      onChange={e => callback(row.data, e)}
                                      accept={action.acceptFiles}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    disabled={action.disabled}
                                    onClick={e => callback(row.data, e)}
                                    sx={{ ml: idx === 0 ? 0 : 1 }}
                                  >
                                    {action.icon && action.icon}
                                    {action.component && action.component}
                                    {action.img && (
                                      <Box bgcolor="common.white" display="flex" borderRadius="50%">
                                        <img src={action.img} alt={action.title} width={24} height={24} />
                                      </Box>
                                    )}
                                  </IconButton>
                                )}
                              </span>
                            </Tooltip>
                          </Box>
                        )
                      })}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            {pagination && (
              <TableFooter>
                <TableRow>
                  <CustomTablePagination
                    count={count}
                    page={pagination.page}
                    onPageChange={(e, newPage: number) => pagination.onPageChange(newPage)}
                    rowsPerPage={pagination.pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  >
                    {selectedItems.length > 0 && checkboxSelectionActions && checkboxSelectionActions.length > 0 && (
                      <Trans
                        i18nKey="common.selectedCountFrom"
                        values={{
                          count: selectedItems.length,
                          fromCount: count < pagination.pageSize ? count : pagination.pageSize,
                        }}
                      />
                    )}
                  </CustomTablePagination>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
    )
  },
)

type CustomTablePaginationProps = {
  children: React.ReactNode
} & TablePaginationProps

const CustomTablePagination = ({ children, ...props }: CustomTablePaginationProps) => {
  return (
    <>
      <TableCell sx={{ position: 'absolute', display: { xs: 'none', md: 'table-cell' } }}>{children}</TableCell>
      <TablePagination {...props}> </TablePagination>
    </>
  )
}

export default MainTable
