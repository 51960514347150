import { ReactElement } from 'react'

import CheckCircle from '@mui/icons-material/CheckCircle'
import Favorite from '@mui/icons-material/Favorite'
import { CatalogFiltersState, SelectedCategory } from 'core/contexts/CatalogProvider'
import { Category } from 'core/types'
import { FiltersState } from 'items/types/types'

export function createFilters(searchTerm: string, filtersState: FiltersState) {
  const filters = []

  if (searchTerm.trim()) {
    filters.push(`search@=*${searchTerm}`)
  }
  if (filtersState.previouslyOrdered) {
    filters.push('Ordered==true')
  }
  if (filtersState.favouriteOnly) {
    filters.push('favouriteOnly==true')
  }
  if (filtersState.isInStock) {
    filters.push('isInStock==true')
  }

  return filters.join(',')
}

export const filterSelectedCategories = (selectedCategories: SelectedCategory[], categories: Category[]): SelectedCategory[] => {
  return selectedCategories.flatMap(selectedCategory => {
    const category = categories.find(cat => cat.id === selectedCategory.category.id)
    if (!category) return []

    const validSubcategories = selectedCategory.subcategories.filter(subcat =>
      category.subcategories.some(catSubcat => catSubcat.id === subcat.id),
    )

    if (validSubcategories.length === 0) return []

    return [
      {
        ...selectedCategory,
        subcategories: validSubcategories,
        areAllSubcategoriesSelected: validSubcategories.length === category.subcategories.length,
        totalSubcategories: category.subcategories.length,
      },
    ]
  })
}

export const otherFiltersItems: { name: keyof CatalogFiltersState; label: string; icon?: ReactElement }[] = [
  {
    name: 'favouriteOnly',
    label: 'orders.favourite',
    icon: <Favorite sx={{ fontSize: '1rem', color: 'error.light', opacity: 0.8 }} />,
  },
  {
    name: 'previouslyOrdered',
    label: 'orders.previouslyOrdered',
  },
  {
    name: 'isInStock',
    label: 'orders.isInStock',
    icon: <CheckCircle color="success" sx={{ fontSize: '1rem' }} />,
  },
]
