import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import spingoImg from 'core/assets/spingo-sign.png'
import { Client } from 'core/types'
import { formatAddress } from 'core/utils'
import { TFunction } from 'i18next'

type Actions = {
  handleEdit: (el: Client) => void
  handleDelete: (el: Client) => void
  handleCheckSpingo: (el: Client) => void
}

export const mapClientsData = (data: Client[], t: TFunction, actions: Actions) => {
  const mappedData = data.map(el => {
    return {
      rowId: el.id,
      data: {
        name: el.name,
        taxNumber: el.taxNumber,
        address: formatAddress(el.invoicingAddress),
        isActive: el.isActive ? <CheckCircleIcon color="success" /> : <RemoveCircleOutlineIcon color="disabled" />,
      },
      actions: el.isActive
        ? [
            {
              title: t('common.edit'),
              icon: <EditIcon />,
              callback: () => actions.handleEdit(el),
            },
            {
              title: t('payment.paymentLimitInSpingo'),
              callback: () => actions.handleCheckSpingo(el),
              img: spingoImg,
            },
            {
              title: t('common.delete'),
              icon: <DeleteIcon />,
              callback: () => actions.handleDelete(el),
              separator: true,
            },
          ]
        : undefined,
    }
  })

  return mappedData
}

export const mapClientsColumns = (t: TFunction) => [
  { title: t('clients.name'), id: 'name' },
  { title: t('clients.taxNumber'), id: 'taxNumber' },
  { title: t('clients.address'), id: 'address' },
  { title: t('clients.isActive'), id: 'isActive' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]

export const cellStyles = [
  {
    id: 'name',
    styles: {
      fontWeight: 700,
      color: 'primary.main',
    },
  },
  {
    id: 'address',
    styles: {
      color: 'text.secondary',
    },
  },
]
