import { SxProps, Theme } from '@mui/material'

export const cardStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexDirection: 'column',
  cursor: 'pointer',
  border: 1,
  boxShadow: 1,
  py: 2.5,
  pr: 4,
  pl: 3,
  position: 'relative',
  transition: 'all 0.3s',
}

export const checkStyles: SxProps<Theme> = {
  position: 'absolute',
  top: '.5rem',
  right: '.5rem',
}
