import { lighterSecondaryBgr } from 'core/theme/palette'

export const commonStyle = {
  mb: 2,
  px: 2,
  bgcolor: lighterSecondaryBgr,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
