import { Delete, PriorityHigh, SwapHoriz } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Tooltip, Typography } from '@mui/material'
import { OrderStatus, PaymentTypeVal, ProductUnit } from 'core/consts'
import { Order, ProductOrder } from 'core/types'
import { formatPricePerUnit } from 'core/utils/formatUtils'
import { TFunction } from 'i18next'
import { DialogTypes } from 'vendor/types/types'

type Actions = {
  handleOpenOrderDialog: (dialogType: DialogTypes, el: ProductOrder | null) => void
}

export const mapProductOrder = (order: Order, t: TFunction, action: Actions) => {
  const isOnlinePayment = [PaymentTypeVal.OnlinePayment, PaymentTypeVal.Spingo].includes(order.paymentMethod)

  const canModifyPaidOnlineOrder = () => (order.status === OrderStatus.Paid || order.status === OrderStatus.Processing) && isOnlinePayment

  const canModifyOrder = () => (order.status === OrderStatus.Placed || order.status === OrderStatus.Processing) && !isOnlinePayment

  const mappedData = order.productOrders.map(el => {
    return {
      rowId: el.id,
      data: {
        name: el.isOverriden ? (
          <s>{el.productName}</s>
        ) : el.isRemoved ? (
          <Typography variant="inherit" component="s" color="error.main">
            {el.productName}
          </Typography>
        ) : (
          el.productName
        ),
        price: formatPricePerUnit(isOnlinePayment ? el.skaloPrice : el.pricePerUnit, el.unit),
        vatPercent: el.vatPercent + '%',
        quantity: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {el.quantity}
            {el.unit !== ProductUnit.Piece && (
              <Tooltip title={t('common.variableWeightProduct')}>
                <PriorityHigh sx={{ fontSize: 32, color: 'error.main' }} />
              </Tooltip>
            )}
          </Box>
        ),
      },
      actions:
        el.isOverriden || el.isRemoved || !canModifyOrder()
          ? el.isOverriden || el.isRemoved || !(canModifyPaidOnlineOrder() && el.unit !== ProductUnit.Piece)
            ? []
            : [
                {
                  title: t('common.edit'),
                  icon: <EditIcon />,
                  callback: () => action.handleOpenOrderDialog(DialogTypes.EDIT_PRODUCT, el),
                },
              ]
          : [
              {
                title: t('common.edit'),
                icon: <EditIcon />,
                callback: () => action.handleOpenOrderDialog(DialogTypes.EDIT_PRODUCT, el),
              },
              {
                title: t('orders.swap'),
                icon: <SwapHoriz />,
                callback: () => action.handleOpenOrderDialog(DialogTypes.SWAP_PRODUCT, el),
              },
              {
                title: t('common.delete'),
                icon: <Delete />,
                callback: () => action.handleOpenOrderDialog(DialogTypes.DELETE_PRODUCT, el),
              },
            ],
    }
  })
  return mappedData
}

export const mapProductOrderColumns = (t: TFunction) => [
  { title: t('acceptOrder.productsList'), id: 'name' },
  { title: t('basket.priceNet'), id: 'price' },
  { title: t('acceptOrder.vat'), id: 'vatPercent' },
  { title: t('acceptOrder.quantity'), id: 'quantity' },
  { title: t('common.actions'), id: 'actions', align: 'right' },
]
