import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUploadDeliverySiteLogo } from 'companies/hooks/useUploadDeliverySiteLogo'
import { NotReadyWrapper } from 'core/components'
import { ActionType } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { AddDeliverySite, Client, DeliverySite, UpdateDeliverySite } from 'core/types'

import { useAddDeliverySite } from '../../hooks/useAddDeliverySite'
import { useDeleteDeliverySite } from '../../hooks/useDeleteDeliverySite'
import { useDeliverSites } from '../../hooks/useDeliverSites'
import { useUpdateDeliverySite } from '../../hooks/useUpdateDeliverySite'
import AddEditDeliverySiteDialog from './AddEditDeliverySiteDialog'
import ConfirmDeleteDeliverySiteDialog from './ConfirmDeleteDeliverySiteDialog'
import DeliverySitesTable from './DeliverySiteTable'

type DeliverySiteManagementDialog = {
  deliverySite: DeliverySite | null
  actionType: ActionType
}
interface DeliverySiteManagementProps {
  clients: Client[]
}

const DeliverySiteManagement = ({ clients }: DeliverySiteManagementProps) => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  const [dialogOpen, setDialogOpen] = useState<DeliverySiteManagementDialog | null>(null)

  const [selectedSite, setSelectedSite] = useState<DeliverySite | null>(null)

  const { addSite, isLoading: isAdding } = useAddDeliverySite()
  const { uploadDeliverySiteLogo } = useUploadDeliverySiteLogo()
  const { updateSite, isLoading: isUpdating } = useUpdateDeliverySite()
  const { deleteSite } = useDeleteDeliverySite(selectedSite?.id ?? 0)

  const { isLoadingDeliverySites, deliverySites, isSuccess, isError, refetchDeliverySites } = useDeliverSites()

  const handleOpenDialog = (actionType: ActionType, deliverySite?: DeliverySite) => {
    setDialogOpen({ actionType, deliverySite: deliverySite ?? null })
    if (deliverySite) {
      setSelectedSite(deliverySite)
    }
  }

  const handleCloseDialog = () => {
    setDialogOpen(null)
    setSelectedSite(null)
  }

  const handleUploadLogo = async (logo: File, deliverySiteId: number) => {
    const formData = new FormData()
    formData.append('DeliverySiteLogo', logo)
    formData.append('DeliverySiteId', deliverySiteId.toString())

    uploadDeliverySiteLogo(formData, {
      onSuccess: () => {
        refetchDeliverySites()
        handleCloseDialog()
        snackbar.success(t('deliverySites.logoUpload.success'))
      },
      onError: () => {
        refetchDeliverySites()
        snackbar.error(t('deliverySites.logoUpload.error'))
      },
    })
  }

  const handleAddSite = (newSite: AddDeliverySite) => {
    const { deliverySiteLogo } = newSite
    addSite(newSite, {
      onSuccess: async (response: any) => {
        const deliverySiteId = response.data.id

        if (deliverySiteLogo) {
          await handleUploadLogo(deliverySiteLogo, deliverySiteId)
        } else {
          refetchDeliverySites()
          snackbar.success(t('deliverySites.addDeliverySite.success'))
          handleCloseDialog()
        }
      },
      onError: () => {
        snackbar.error(t('deliverySites.addDeliverySite.error'))
      },
    })
  }

  const handleUpdateSite = (updatedSite: UpdateDeliverySite) => {
    const { deliverySiteLogo } = updatedSite
    updateSite(updatedSite, {
      onSuccess: async (response: any) => {
        const deliverySiteId = response.data.id

        if (deliverySiteLogo) {
          await handleUploadLogo(deliverySiteLogo, deliverySiteId)
        } else {
          refetchDeliverySites()
          handleCloseDialog()
          snackbar.success(t('deliverySites.updateDeliverySite.success'))
        }
      },
      onError: () => {
        snackbar.error(t('deliverySites.updateDeliverySite.error'))
      },
    })
  }

  const handleConfirmDelete = () => {
    if (selectedSite) {
      deleteSite(selectedSite.id, {
        onSuccess: () => {
          handleCloseDialog()
          refetchDeliverySites()
        },
        onError: () => {
          snackbar.error(t('common.errors.unexpected.subTitle'))
        },
      })
    }
  }

  return (
    <>
      <NotReadyWrapper
        isLoading={isLoadingDeliverySites}
        isSuccess={isSuccess}
        isError={isError}
        errorMsg={t('deliverySites.errors.unexpected.subTitle')}
      >
        {deliverySites && (
          <DeliverySitesTable
            deliverySites={deliverySites}
            onAddButtonClicked={() => handleOpenDialog(ActionType.ADD)}
            onEdit={site => handleOpenDialog(ActionType.EDIT, site)}
            onDelete={site => handleOpenDialog(ActionType.DELETE, site)}
          />
        )}
      </NotReadyWrapper>
      {dialogOpen?.actionType === ActionType.ADD && (
        <AddEditDeliverySiteDialog onClose={handleCloseDialog} onAction={handleAddSite} processing={isAdding} clients={clients} />
      )}
      {dialogOpen?.actionType === ActionType.EDIT && (
        <AddEditDeliverySiteDialog
          onClose={handleCloseDialog}
          onAction={handleUpdateSite}
          processing={isAdding || isUpdating}
          clients={clients}
          deliverySite={dialogOpen?.deliverySite}
        />
      )}
      {dialogOpen?.actionType === ActionType.DELETE && (
        <ConfirmDeleteDeliverySiteDialog open onClose={handleCloseDialog} onConfirm={handleConfirmDelete} site={dialogOpen.deliverySite} />
      )}
    </>
  )
}

export default DeliverySiteManagement
