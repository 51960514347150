/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactNode, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  MenuItem,
  Autocomplete as MuiAutocomplete,
  SelectProps as MuiSelectProps,
  OutlinedInputProps,
  Typography,
} from '@mui/material'
import apiEndpoints, { Endpoint } from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { lightPalette } from 'core/theme/palette'
import { CallbackFnData, FilterNameType, PaginatedResult, SelectOption, SelectType } from 'core/types'
import { mapFilterLabel, mapFiltersData } from 'core/utils'

import { TextField } from '../TextField'
import { commonSelectItemStyle } from './styles'

export type AutocompleteProps = MuiSelectProps & {
  error?: boolean
  errorHelperText?: string
  inputProps?: OutlinedInputProps
  type?: SelectType
  setValue: (val: any) => void
  loading?: boolean
  pageSize?: number

  apiRequest: keyof typeof apiEndpoints
  apiParams?: any
  apiDisabled?: boolean
  options?: SelectOption[]

  addNewOption?: boolean
  filterBy?: string
  sortBy?: string
  filterName: FilterNameType
  value: SelectOption | null
  noPagination?: boolean
  callbackFn?: (data: CallbackFnData) => void
}
export const useGetDataRequest = (api: Endpoint, disabled: boolean) => {
  return useRequest<PaginatedResult<any>>(api, { enabled: !disabled })
}

// TBD. need more tests and adjustments, but main functionality should work
export const Autocomplete = memo(
  ({
    label,
    value,
    error,
    errorHelperText,
    inputProps,
    disabled,
    apiDisabled,
    sx = [],
    loading,
    apiRequest,
    setValue,
    filterName,
    filterBy = 'Name',
    sortBy = 'name',
    noPagination,
    callbackFn,
    options,
    apiParams,
    pageSize = 25,
    addNewOption,
  }: AutocompleteProps) => {
    const { t, i18n } = useTranslation()
    const inputDisabled = disabled || inputProps?.disabled || apiDisabled
    const sxStyle = sx ? (Array.isArray(sx) ? sx : [sx]) : []

    const [page, setPage] = useState(0)

    const [inputValue, setInputValue] = useState('')

    const { data, isLoading, isSuccess, isFetching, isError } = useGetDataRequest(
      // @ts-ignore
      apiEndpoints[apiRequest](
        noPagination
          ? {
              ...apiParams,
            }
          : {
              Page: page + 1,
              PageSize: pageSize,
              Sorts: sortBy,
              Filters: inputValue ? `(${filterBy})@=*${inputValue}` : '',
              ...apiParams,
            },
      ),
      Boolean(Boolean(options) || apiDisabled),
    )

    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([])

    const handleAutocompleteChange = (val: SelectOption | null) => {
      setPage(0)
      setValue(val)
    }

    const mapOptions = (data: any) => mapFiltersData(filterName, data, t, { noAll: true })
    useEffect(() => {
      if ((noPagination && data) || (!noPagination && data?.items)) {
        if (page === 0) {
          setFilteredOptions([...mapOptions(data)])
        } else {
          if (value || inputValue === '') {
            setFilteredOptions([...filteredOptions, ...mapOptions(data)])
          } else {
            setFilteredOptions([...mapOptions(data)])
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, inputValue, page])

    useEffect(() => {
      if (typeof callbackFn === 'function') {
        if (!isFetching && (isSuccess || isError)) {
          callbackFn({
            callbackType: isError ? 'isError' : 'isSuccess',
            filterName,
            data,
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterName, data, isSuccess, isError, isFetching])

    const getOptions = useCallback(() => {
      if (options) return options
      else if (
        filteredOptions &&
        inputValue.length > 2 &&
        addNewOption &&
        !filteredOptions.find(el => el.name?.toString().toLowerCase() === inputValue.toLowerCase())
      ) {
        return [
          ...filteredOptions,
          {
            label: `${t('common.add')} "${inputValue}"`,
            name: inputValue,
            id: 'addNeVal',
            styles: { color: 'info.dark' },
          },
        ]
      } else return filteredOptions
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, filteredOptions, addNewOption, inputValue, i18n.language])

    return (
      <MuiAutocomplete
        sx={[{ minWidth: { xs: '100%', sm: '18rem' } }, ...sxStyle]}
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          // setPage(0)
          setInputValue(newInputValue)
        }}
        // disableCloseOnSelect
        // open
        loading={loading || isLoading}
        disabled={inputDisabled}
        options={getOptions()}
        groupBy={option => option.otherData?.parent}
        getOptionLabel={(option: any) => option.name}
        noOptionsText={t('filters.noDataFound')}
        renderInput={params => (
          <TextField
            {...params}
            name={filterName}
            label={label ?? mapFilterLabel(filterName, t, inputDisabled)}
            error={error}
            errorHelperText={errorHelperText}
          />
        )}
        renderOption={(props, o: any) => (
          <MenuItem
            value={o.id}
            sx={[
              commonSelectItemStyle,
              { bgcolor: o.id.toString() === value?.id ? 'grey.50' : 'inherit' },
              ...(Array.isArray(o.styles) ? o.styles : [o.styles]),
            ]}
            disabled={o.disabled}
            {...props}
            key={o.id.toString()}
          >
            <Typography display="flex" alignItems="center" component="div">
              {o?.icon && o.iconPosition === 'start' && <IconBox>{o.icon}</IconBox>}
              {o.label ? o.label : o.name ? o.name : o.id}
              {o?.icon && (!o.iconPosition || o.iconPosition === 'end') && <IconBox>{o.icon}</IconBox>}
            </Typography>
          </MenuItem>
        )}
        onChange={(_event, value) => {
          handleAutocompleteChange(value)
          // setInputValue('')
        }}
        ListboxProps={{
          onScroll: event => {
            if (Number(data?.totalCount) > pageSize * (page + 1) && !noPagination) {
              const listboxNodeEl = event.currentTarget
              if (listboxNodeEl.scrollTop + listboxNodeEl.clientHeight > listboxNodeEl.scrollHeight - 0.5) {
                setPage((prevPage: number) => prevPage + 1)
              }
            }
          },
          style: { maxHeight: 300, overflow: 'auto', border: 1 },
        }}
        componentsProps={{
          paper: {
            style: { border: `1px solid ${lightPalette.grey[300]}` },
          },
        }}
      />
    )
  },
)

export const IconBox = ({ children }: { children: ReactNode }) => (
  <Box sx={{ minHeight: '2rem', minWidth: 'auto', my: -0.5, display: 'flex', alignItems: 'center' }}>{children}</Box>
)
