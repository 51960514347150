import { Link as RouterLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Link, Typography } from '@mui/material'
import { useAuth } from 'auth/contexts/AuthProvider'
import { lighterSecondaryBgr } from 'core/theme/palette'
import { NotificationEvent } from 'core/types'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { getOrderPath } from './helpers/orderPathFinder'
import { notificationStyle } from './styles'

export interface SingleNotificationProps extends NotificationEvent {
  onRead: (id: string) => void
  onClose: () => void
}

const SingleNotification: React.FC<SingleNotificationProps> = ({
  id,
  timestamp,
  title,
  orderId,
  read,
  onClose,
  content,
  onRead,
}: SingleNotificationProps) => {
  const { userInfo } = useAuth()
  const roles = userInfo?.roles || []

  const orderPath = getOrderPath(roles)
  return (
    <Box sx={[notificationStyle, { bgcolor: read ? 'inherit' : lighterSecondaryBgr }]}>
      <Typography color="grey.500" mb={1}>
        {dayjs(timestamp).fromNow()}
      </Typography>
      <Typography variant="h6" mb={1.5} mr={5}>
        {title}
      </Typography>
      {!read && (
        <IconButton
          aria-label="read notification"
          onClick={() => onRead(id)}
          sx={{
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {content && (
        <Typography variant="body1" mb={1.5}>
          {content}
        </Typography>
      )}
      {orderId && (
        <Link
          to={`/${orderPath}/${orderId}`}
          onClick={() => {
            onClose()
            onRead(id)
          }}
          underline="hover"
          component={RouterLink}
        >
          {t('orders.seeDetails')}
        </Link>
      )}
    </Box>
  )
}

export default SingleNotification
