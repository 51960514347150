import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import { useGetManagedDeliverySites } from 'companies/hooks'
import { UserRole } from 'core/consts'
import { Client, ManagedDeliverySites, User, VendorListItem } from 'core/types'
import { useFormik } from 'formik'
import { useAllClients, useAllVendors } from 'management/hooks'
import * as Yup from 'yup'

type EditUserDialogProps = {
  open: boolean
  onClose: () => void
  user: User | null
  onSave: (user: User) => void
  processing: boolean
}

const startRole = (user: User | null): UserRole => {
  if (user) {
    if (user.clients.length > 0) return UserRole.CLIENT
    if (user.vendors.length > 0) return UserRole.VENDOR
    if (user.managedDeliverySites.length > 0) return UserRole.RESTAURANT_MANAGER
  }
  return UserRole.CLIENT
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({ open, onClose, user, onSave, processing }) => {
  const [selectedRole, setSelectedRole] = useState(() => startRole(user))
  const [editedUser, setEditedUser] = useState<User | null>(user)
  const { t } = useTranslation()

  const [clientPage, setClientPage] = useState(0)
  const [vendorPage, setVendorPage] = useState(0)
  const pageSize = 100

  const { allClientsData } = useAllClients({ Page: clientPage + 1, PageSize: pageSize })
  const [selectedClientId, setSelectedClientId] = useState('')

  const { data: managedDeliverySitesList } = useGetManagedDeliverySites(selectedClientId)

  const { vendorsData } = useAllVendors({
    Page: vendorPage + 1,
    PageSize: pageSize,
    Filters: '',
  })

  const [filteredClients, setFilteredClients] = useState<Client[]>([])
  const [filteredVendors, setFilteredVendors] = useState<VendorListItem[]>([])
  const [filteredDeliverySites, setFilteredDeliverySites] = useState<ManagedDeliverySites[]>([])

  const [clientInputValue, setClientInputValue] = useState('')
  const [vendorInputValue, setVendorInputValue] = useState('')
  const [deliverySiteInputValue, setDeliverySiteInputValue] = useState('')

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      emailConfirmed: user?.emailConfirmed || false,
      clients: user?.clients || [],
      vendors: user?.vendors || [],
      managedDeliverySites: user?.managedDeliverySites || [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('common.validations.required')),
      lastName: Yup.string().required(t('common.validations.required')),
      email: Yup.string().email(t('common.validations.invalidEmail')).required(t('common.validations.required')),
    }),
    onSubmit: values => {
      if (user) {
        const updatedUser = { ...user, ...values }
        if (selectedRole === UserRole.RESTAURANT_MANAGER) {
          updatedUser.clients = []
          updatedUser.vendors = []
        } else if (selectedRole === UserRole.VENDOR) {
          updatedUser.clients = []
          updatedUser.managedDeliverySites = []
        } else if (selectedRole === UserRole.CLIENT) {
          updatedUser.vendors = []
          updatedUser.managedDeliverySites = []
        }
        onSave(updatedUser)
      }
    },

    enableReinitialize: true,
  })

  useEffect(() => {
    setEditedUser(user)
  }, [user])

  const handleClientAutocompleteChange = (value: Client | null) => {
    if (value && editedUser) {
      const updatedClients = editedUser.clients.includes(value) ? editedUser.clients : [...editedUser.clients, value]

      formik.setFieldValue('clients', updatedClients)

      setEditedUser({
        ...editedUser,
        clients: updatedClients,
      })
    }
  }

  const handleVendorAutocompleteChange = (value: VendorListItem | null) => {
    if (value && editedUser) {
      const updatedVendors = editedUser.vendors.includes(value) ? editedUser.vendors : [...editedUser.vendors, value]

      formik.setFieldValue('vendors', updatedVendors)
      setEditedUser({
        ...editedUser,
        vendors: updatedVendors,
      })
    }
  }

  const handleDeliverySiteAutocompleteChange = (value: ManagedDeliverySites | null) => {
    if (value && editedUser) {
      const updatedDeliverySite = editedUser.managedDeliverySites.includes(value)
        ? editedUser.managedDeliverySites
        : [...editedUser.managedDeliverySites, value]

      formik.setFieldValue('managedDeliverySites', updatedDeliverySite)
      setEditedUser({
        ...editedUser,
        managedDeliverySites: updatedDeliverySite,
      })
    }
  }

  useEffect(() => {
    if (allClientsData?.items) {
      const newFilteredClients = allClientsData.items.filter(
        client => !editedUser?.clients.some(editedClient => editedClient.id === client.id),
      )
      setFilteredClients(newFilteredClients)
    }

    if (vendorsData?.items) {
      const newFilteredVendors = vendorsData.items.filter(
        vendor => !editedUser?.vendors.some(editedVendor => editedVendor.id === vendor.id),
      )
      setFilteredVendors(newFilteredVendors)
    }
    if (managedDeliverySitesList) {
      const newFilteredDeliverySites = managedDeliverySitesList.filter(
        managedDeliverySite =>
          !editedUser?.managedDeliverySites.some(editedDeliverySite => editedDeliverySite.id === managedDeliverySite.id),
      )
      setFilteredDeliverySites(newFilteredDeliverySites)
    }
  }, [
    allClientsData?.items,
    vendorsData?.items,
    editedUser?.clients,
    editedUser?.vendors,
    editedUser?.managedDeliverySites,
    managedDeliverySitesList,
  ])

  const handleClientDelete = (clientId: string) => {
    setEditedUser(prevUser => ({
      ...prevUser!,
      clients: prevUser!.clients.filter(client => client.id !== clientId),
    }))
    const updatedClientIds = formik.values.clients.filter(client => client.id !== clientId)
    formik.setFieldValue('clients', updatedClientIds)
  }

  const handleVendorDelete = (vendorId: string) => {
    setEditedUser(prevUser => ({
      ...prevUser!,
      vendors: prevUser!.vendors.filter(vendor => vendor.id !== vendorId),
    }))
    const updatedVendorIds = formik.values.vendors.filter(vendor => vendor.id !== vendorId)
    formik.setFieldValue('vendors', updatedVendorIds)
  }

  const handleDeliverySiteDelete = (deliverySiteId: number) => {
    setEditedUser(prevUser => ({
      ...prevUser!,
      managedDeliverySites: prevUser!.managedDeliverySites.filter(deliverySite => deliverySite.id !== deliverySiteId),
    }))
    const updatedDeliverySiteIds = formik.values.managedDeliverySites.filter(deliverySite => deliverySite.id !== deliverySiteId)
    formik.setFieldValue('managedDeliverySites', updatedDeliverySiteIds)
  }

  const handleClientSelectChange = (event: any) => {
    const selectedId = event.target.value
    setSelectedClientId(selectedId)
  }

  if (!editedUser) return null

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <DialogTitle id="edit-client-dialog-title">
          {t('users.editUser')}
          <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={t('users.firstName')}
              name="firstName"
              disabled={processing}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={t('users.lastName')}
              name="lastName"
              disabled={processing}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('users.email')}
              name="email"
              disabled={processing}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={formik.values.emailConfirmed} onChange={formik.handleChange} name="emailConfirmed" color="primary" />
                }
                label={t('users.emailConfirmed')}
              />
              <FormControl>
                <FormLabel id="radio-buttons-group-label">{t('users.selectRole')}</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  value={selectedRole}
                  onChange={event => setSelectedRole(event.target.value as UserRole)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={UserRole.CLIENT} control={<Radio />} label={t('users.role.client')} />
                  <FormControlLabel value={UserRole.RESTAURANT_MANAGER} control={<Radio />} label={t('users.role.manager')} />
                  <FormControlLabel value={UserRole.VENDOR} control={<Radio />} label={t('users.role.vendor')} />
                </RadioGroup>
              </FormControl>
            </Box>
            {selectedRole === UserRole.CLIENT && (
              <div>
                <Autocomplete
                  value={null}
                  inputValue={clientInputValue}
                  onInputChange={(event, newInputValue) => {
                    setClientInputValue(newInputValue)
                  }}
                  options={filteredClients}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label={t('users.addClient')} />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    handleClientAutocompleteChange(value)
                    setClientInputValue('')
                  }}
                  onScroll={event => {
                    const listboxNode = event.currentTarget
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                      setClientPage(prevPage => prevPage + 1)
                    }
                  }}
                />
                <div style={{ margin: '0.6rem 0' }}>
                  {editedUser.clients.map(client => (
                    <Chip
                      key={client.id}
                      label={client.name}
                      onDelete={() => handleClientDelete(client.id)}
                      color="primary"
                      style={{ margin: '0.3rem' }}
                    />
                  ))}
                </div>
              </div>
            )}
            {selectedRole === UserRole.RESTAURANT_MANAGER && (
              <div>
                <Select
                  value={selectedClientId}
                  onChange={handleClientSelectChange}
                  displayEmpty
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        maxWidth: 250,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t('users.selectCompany')}
                  </MenuItem>
                  {allClientsData?.items.map(client => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
                <Autocomplete
                  style={{ margin: '0.6rem 0' }}
                  value={null}
                  inputValue={deliverySiteInputValue}
                  onInputChange={(event, newInputValue) => {
                    setDeliverySiteInputValue(newInputValue)
                  }}
                  options={filteredDeliverySites}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label={t('users.addRestaurant')} />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  onChange={(event, value) => {
                    handleDeliverySiteAutocompleteChange(value)
                    setDeliverySiteInputValue('')
                  }}
                />
                <div style={{ margin: '0.6rem 0' }}>
                  {editedUser.managedDeliverySites.map(deliverySite => (
                    <Chip
                      key={deliverySite.id}
                      label={deliverySite.name}
                      onDelete={() => handleDeliverySiteDelete(deliverySite.id)}
                      color="primary"
                      style={{ margin: '0.3rem' }}
                    />
                  ))}
                </div>
              </div>
            )}
            {selectedRole === UserRole.VENDOR && (
              <div>
                <Autocomplete
                  value={null}
                  inputValue={vendorInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setVendorInputValue(newInputValue)
                  }}
                  options={filteredVendors}
                  getOptionLabel={option => option.name}
                  renderInput={params => <TextField {...params} label={t('users.addVendor')} />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  onChange={(_event, value) => {
                    handleVendorAutocompleteChange(value)
                    setVendorInputValue('')
                  }}
                  onScroll={event => {
                    const listboxNode = event.currentTarget
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                      setVendorPage(prevPage => prevPage + 1)
                    }
                  }}
                />
                <div style={{ margin: '0.6rem 0' }}>
                  {editedUser.vendors.map(vendor => (
                    <Chip
                      key={vendor.id}
                      label={vendor.name}
                      onDelete={() => handleVendorDelete(vendor.id)}
                      color="primary"
                      style={{ margin: '0.3rem' }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
          <LoadingButton loading={processing} type="submit" variant="contained">
            {t('common.saveChanges')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditUserDialog
