import { errorCode } from './errorCode'
import { message } from './message'
import { notification } from './notification'
import { platformManagement } from './platformManagement'
import { product } from './product'
import { termsOfSubscription } from './termsOfSubscription'
import { termsOfUsage } from './termsOfUsage'
import translation from './translation.json'

export const messages_pl = {
  termsOfUsage,
  termsOfSubscription,
  notification,
  product,
  platformManagement,
  errorCode,
  message,
  ...translation,
}
