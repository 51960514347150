import CheckCircle from '@mui/icons-material/CheckCircle'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import { Theme, darken, lighten } from '@mui/material'

import { globals } from './globals'

export const createThemeComponents = (theme: Theme) => ({
  MuiCssBaseline: {
    styleOverrides: {
      ...globals,
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(3),
        '&.Mui-expanded:last-of-type': {
          marginBottom: theme.spacing(3),
        },
        '&:before': {
          content: 'none',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 3, 3),
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
      content: {
        margin: 0,
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 1,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        '&.MuiAppBar-colorDefault': {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        color: 'inherit',
        backgroundColor: theme.palette.background.default,
      },
      rounded: {
        borderRadius: 8,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        padding: '11.5px 24px',
        textTransform: 'none' as any,
      },
      label: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      sizeSmall: {
        padding: '8px 16px',
      },
      // smaller padding cause of border
      outlined: {
        padding: '10.5px 23px',
      },
      outlinedSizeSmall: {
        padding: '7px 15px',
      },
      outlinedPrimary: {
        backgroundColor:
          theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.8) : lighten(theme.palette.primary.main, 0.95),
        // backgroundColor: theme.palette.grey[100],
        '&:hover:not(.Mui-disabled)': {
          backgroundColor:
            theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.85) : lighten(theme.palette.primary.main, 0.9),
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // No more ripple, on the whole application
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        justifyContent: 'flex-end',
        padding: '0 24px 24px 24px',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: '24px 24px 0 24px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: <CheckCircle />,
      indeterminateIcon: <RemoveCircle />,
      icon: <RadioButtonUnchecked />,
    },
    styleOverrides: {
      root: {
        padding: '0.5rem',
        '& MuiSvgIcon-fontSizeSmall': {
          fontSize: '1rem',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      root: {
        '&.MuiChip-clickableColorDefault.MuiChip-filledDefault': {
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 24,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '&.MuiTypography-root': {
          fontSize: '1.25rem',
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
        border: 'none; !important',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        lineHeight: 'inherit',
        textTransform: 'none' as any,
        '&.MuiFab-secondary': {
          color: theme.palette.text.primary,
        },
        '&.MuiFab-colorInherit': {
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiInternalClock: {
    styleOverrides: {
      clock: {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  MuiInternalDateTimePickerTabs: {
    styleOverrides: {
      tabs: {
        backgroundColor: theme.palette.background.default,
        '& MuiTabs-indicator': {
          height: 0,
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        height: 12,
      },
    },
  },
  MuiList: {
    defaultProps: {
      disablePadding: true,
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        paddingTop: 12,
        paddingBottom: 12,
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: 8,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.paper} inset`,
        },
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 1,
    },
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        borderRadius: 16,
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary' as const,
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        padding: '10px 16px',
        maxWidth: 'initial !important',
        minHeight: 'initial !important',
        minWidth: 'initial !important',
        textTransform: 'none' as any,
        '&:hover:not(.Mui-selected)': {
          color: theme.palette.primary.light,
        },
        '&.Mui-selected': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 0,
        padding: '16px 12px',
      },
      head: {
        color: theme.palette.text.secondary,
      },
      sizeSmall: {
        padding: '12px 16px',
      },
    },
  },
  MuiTimeline: {
    styleOverrides: {
      root: {
        padding: '0 0 0 16px',
      },
    },
  },
  MuiTimelineContent: {
    styleOverrides: {
      root: {
        padding: '12px 16px',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: theme.palette.text.secondary,
        borderRadius: '12px !important',
        border: 'none',
        textTransform: 'none' as any,
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.default,
        padding: 5,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      icon: {
        alignItems: 'center',
      },
    },
  },
})
