import { UserRole } from 'core/consts'

export const getOrderPath = (roles: UserRole[]) => {
  if (roles.includes(UserRole.ADMIN)) {
    return 'admin-orders'
  } else if (roles.includes(UserRole.CLIENT) || roles.includes(UserRole.RESTAURANT_MANAGER)) {
    return 'my-orders'
  } else if (roles.includes(UserRole.VENDOR)) {
    return 'vendor-orders'
  } else {
    return ''
  }
}
