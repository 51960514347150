import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { KeyboardDoubleArrowRight, ListAlt, MonetizationOn, ShoppingBasket } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import { useAuth } from 'auth/contexts/AuthProvider'
import { DotykackaReport, VendorsChart } from 'client/components'
import { useDashboard } from 'client/hooks'
import {
  cardsData,
  catalogCellStyles,
  filterUniqueVendors,
  mapCatalogColumns,
  mapCatalogData,
  mapUserOrdersColumns,
  mapUserOrdersData,
  ordersCellStyles,
  sumTotalCost,
} from 'client/utils'
import { InfoCard, MainPaper, MainTable, NotReadyWrapper, PaperTitle } from 'core/components'

const Dashboard = () => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { userInfo } = useAuth()

  const { ordersData, productsData, isLoading, isSuccess, isError } = useDashboard()

  const calculatedData = useMemo(() => {
    return ordersData
      ? {
          ordersTotalCount: ordersData?.totalCount,
          ordersTotalCost: sumTotalCost(ordersData.items).toFixed(2),
          ordersTotalAverage: (sumTotalCost(ordersData.items) / ordersData?.totalCount).toFixed(2),
          uniqueVendors: filterUniqueVendors(ordersData.items),
        }
      : null
  }, [ordersData])

  return (
    <NotReadyWrapper isLoading={isLoading} isSuccess={isSuccess} isError={isError}>
      {calculatedData && (
        <>
          {/* title */}
          <Box>
            <Typography variant="h1" fontWeight={500} mb={2}>
              <Trans
                i18nKey="dashboard.welcome"
                values={{
                  name: userInfo?.firstName,
                }}
              />
            </Typography>
            <Typography color="textSecondary" component="p" gutterBottom mb={4} variant="subtitle1">
              {t('admin.home.welcome.message')}
            </Typography>
          </Box>

          {/* cards */}
          <Grid container spacing={2}>
            {cardsData(calculatedData, palette).map(el => (
              <Grid key={el.title} item xs={12} sm={6} xl={3}>
                <InfoCard {...el} />
              </Grid>
            ))}
          </Grid>

          {/* DotykackaReport */}
          <Grid container spacing={2} alignItems="stretch" my={2}>
            <Grid item xs={12}>
              <MainPaper sx={{ height: { xs: 'auto', lg: '100%' } }}>
                <DotykackaReport />
              </MainPaper>
            </Grid>
          </Grid>

          {/* orders */}
          <Grid container spacing={2} alignItems="stretch" my={2}>
            {ordersData.items?.length > 0 ? (
              <>
                <Grid item xs={12} lg={5} xl={4}>
                  <MainPaper sx={{ height: { xs: 'auto', lg: '100%' } }}>
                    <PaperTitle
                      title={t('finanse.orderedFromVendors')}
                      rightEl={
                        <Button component={RouterLink} startIcon={<MonetizationOn />} to="/client-finances">
                          {t('page.title.clientFinances')}
                        </Button>
                      }
                    />
                    <VendorsChart ordersData={ordersData.items} />
                  </MainPaper>
                </Grid>
                <Grid item xs={12} lg={7} xl={8}>
                  <MainPaper sx={{ height: { xs: 'auto', lg: '100%' } }}>
                    <PaperTitle
                      title={t('finanse.recentDeliveredOrders')}
                      rightEl={
                        <Button component={RouterLink} startIcon={<ListAlt />} to="/my-orders">
                          {t('admin.drawer.menu.myOrders')}
                        </Button>
                      }
                    />
                    <MainTable
                      columns={mapUserOrdersColumns(t)}
                      rows={mapUserOrdersData(ordersData.items.slice(0, 5), t)}
                      count={ordersData?.totalCount}
                      cellStyles={ordersCellStyles}
                    />
                  </MainPaper>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Button variant="contained" component={RouterLink} to="/catalog" endIcon={<KeyboardDoubleArrowRight />}>
                  {t('finanse.placeYourFirstOrder')}
                </Button>
              </Grid>
            )}
          </Grid>

          {/* catalog */}
          {productsData?.items?.length > 0 && (
            <Grid container spacing={2} alignItems="stretch" my={2}>
              <Grid item xs={12}>
                <MainPaper sx={{ height: { xs: 'auto', xl: '100%' } }}>
                  <PaperTitle
                    title={t('finanse.mostOrderedProducts')}
                    rightEl={
                      <Button component={RouterLink} startIcon={<ShoppingBasket />} to="/catalog">
                        {t('admin.drawer.menu.orders')}
                      </Button>
                    }
                  />
                  <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
                    <MainTable
                      columns={mapCatalogColumns(t)}
                      rows={mapCatalogData(productsData, t)}
                      count={productsData.totalCount}
                      cellStyles={catalogCellStyles}
                    />
                  </Box>
                </MainPaper>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </NotReadyWrapper>
  )
}

export default Dashboard
