import { Dispatch, Fragment, SetStateAction, memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Box, Button } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Autocomplete, LabelWithIcon, SearchComponent, Select, StyledSearchContainer } from 'core/components'
import { useFetchMultiple } from 'core/hooks'
import { FilterNameType, FilterType, FiltersState, MappedSingleFilter, SelectType, SingleFilter, SortOption } from 'core/types'
import { mapFilterLabel, mapFiltersData } from 'core/utils'

export interface MainFiltersProps {
  handleSearch: (searchTerm: string) => void
  sort: string
  setSort: (sort: string) => void
  sortOptions: SortOption[]
  callbackFn?: (mappedData: MappedSingleFilter) => void
  filters: SingleFilter[]
  filtersState: FiltersState | any
  setFiltersState: Dispatch<SetStateAction<FiltersState>>
  clearAllFilters?: () => void
}

export const MainFilters = memo(
  ({ filters, filtersState, setFiltersState, handleSearch, sort, setSort, sortOptions, callbackFn, clearAllFilters }: MainFiltersProps) => {
    const { t } = useTranslation()

    const { mappedData } = useFetchMultiple(filters.filter(el => el.api))

    const onChangeFilters = useCallback((filterName: FilterNameType, e: any) => {
      setFiltersState(prev => ({
        ...prev,
        [filterName]: e.target.value,
      }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeFiltersVal = useCallback((filterName: string, val: any) => {
      setFiltersState(prev => ({
        ...prev,
        [filterName]: val,
      }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSetSort = useCallback((e: any) => {
      setSort(e.target.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const callback = filters.filter(el => el.callback && el.api)
      if (callback.length > 0 && typeof callbackFn === 'function') {
        callback.forEach(c => {
          const success = mappedData.find(
            el => el.filterName === c.filterName && el.apiResult.isSuccess && !el.apiResult.isLoading && !el.apiResult.isFetching,
          )
          if (success) {
            callbackFn(success as MappedSingleFilter)
          }
        })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappedData, filters])

    const allData = useMemo(
      () =>
        [
          ...mappedData,
          ...filters
            .filter(el => el.data)
            .map(el => ({
              filterName: el.filterName,
              filterType: el.filterType,
              apiResult: {
                isSuccess: true,
                data: { data: el.data },
              },
            })),
        ] as { filterName: FilterNameType; filterType: FilterType; apiResult: any }[],
      [mappedData, filters],
    )

    const onClearAllFilters = useCallback(() => {
      if (typeof clearAllFilters === 'function') {
        clearAllFilters()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filtersVisible = allData.some(
      filter =>
        filter.apiResult.data?.data?.length > 1 ||
        (filter.apiResult.data?.data?.items?.length > 1 && filter.apiResult.data?.data?.totalCount < 41),
    )

    return (
      <Box mb={3}>
        <StyledSearchContainer>
          <SearchComponent onSearch={handleSearch} sx={{ flex: 1, maxWidth: '36.5rem' }} />
          <Select
            fullWidth
            name="sort"
            label={<LabelWithIcon label="orders.sortTitle" icon={ImportExportIcon} />}
            value={sort}
            onChange={handleSetSort}
            options={sortOptions}
          />
        </StyledSearchContainer>
        <Box display="flex" gap={{ xs: 3, md: 2 }} justifyContent="space-between">
          <Box display="flex" gap={{ xs: 2, md: 1 }} flexWrap="wrap" sx={{ flexDirection: { xs: 'column', sm: 'row', width: '100%' } }}>
            {filters
              .filter(el => el.apiRequest)
              .map(el => (
                <Autocomplete
                  key={el.filterName}
                  name={el.filterName}
                  filterName={el.filterName}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  apiRequest={el.apiRequest}
                  value={filtersState[el.filterName]}
                  setValue={(val: any) => onChangeFiltersVal(el.filterName, val)}
                  noPagination={el.noPagination}
                />
              ))}
            {allData.map(filter => (
              <Fragment key={filter.filterName}>
                {filter.apiResult.isSuccess &&
                  (filter.apiResult.data?.data?.length > 1 ||
                    // temporary solution to limit data displayed in the select
                    (filter.apiResult.data?.data?.items?.length > 1 && filter.apiResult.data?.data?.totalCount < 41)) && (
                    <Select
                      fullWidth
                      name={filter.filterName}
                      label={mapFilterLabel(filter.filterName, t)}
                      value={filtersState[filter.filterName]}
                      onChange={e => onChangeFilters(filter.filterName, e)}
                      options={mapFiltersData(filter.filterName, filter.apiResult.data?.data, t)}
                      type={filter.filterType === FilterType.SELECT_CHIP ? SelectType.CHIP : SelectType.DEFAULT}
                    />
                  )}
              </Fragment>
            ))}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {filters
                .filter(el => el.filterType === FilterType.DATEPICKER)
                .map(el => (
                  <DatePicker
                    key={el.filterName}
                    name={el.filterName}
                    onChange={value => {
                      onChangeFiltersVal(el.filterName, value)
                    }}
                    label={mapFilterLabel(el.filterName, t)}
                    value={filtersState[el.filterName]}
                    format="DD-MM-YYYY"
                    slotProps={{
                      field: { clearable: true, onClear: () => onChangeFiltersVal(el.filterName, null) },
                    }}
                    sx={{ bgcolor: 'background.paper' }}
                  />
                ))}
            </LocalizationProvider>
            {(filtersVisible || filters.filter(el => el.apiRequest).length > 0) && typeof clearAllFilters === 'function' && (
              <Button variant="outlined" endIcon={<ClearIcon />} onClick={onClearAllFilters}>
                {t('common.clearFilters')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
)
