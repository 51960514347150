import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Container } from '@mui/material'
import { menuRoutes } from 'config/menuRoutes'
import { Drawer, Footer, SettingsDrawer } from 'core/components'
import { useSettings } from 'core/contexts/SettingsProvider'

import AppBar from './AppBar'
import { containerStyle } from './styles'

interface MainLayoutProps {
  title?: React.ReactNode
  children: React.ReactNode
}

export const MainLayout = ({ title, children }: MainLayoutProps) => {
  const { t } = useTranslation()
  const [settingsOpen, setSettingsOpen] = useState(false)

  const { collapsed, open, toggleDrawer } = useSettings()

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        collapsed={collapsed}
        mobileOpen={open}
        onDrawerToggle={toggleDrawer}
        onSettingsToggle={handleSettingsToggle}
        items={menuRoutes(t)}
      />
      <SettingsDrawer onDrawerToggle={handleSettingsToggle} open={settingsOpen} />
      <AppBar title={title ?? ''} />
      <Box component="main" sx={containerStyle}>
        <Container maxWidth="xl" sx={{ mb: 6 }}>
          {children}
        </Container>
        <Footer linksVisible />
      </Box>
    </Box>
  )
}
