import { useTranslation } from 'react-i18next'

import { Box, Grid } from '@mui/material'
import { ControlledSelect, ControlledTextField } from 'core/components'
import { districtListSelect } from 'core/utils'

const AddressData = ({ addressKey }: { addressKey: string }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={2} mb={3}>
        <Grid item xs={12} sm>
          <ControlledTextField name={`${addressKey}.street`} label={t('address.street.label')} required />
        </Grid>

        <Grid item xs={6} sm={3}>
          <ControlledTextField name={`${addressKey}.streetNumber`} label={t('address.streetNumber.label')} required />
        </Grid>

        <Grid item xs={6} sm={3}>
          <ControlledTextField name={`${addressKey}.apartmentNumber`} label={t('address.apartmentNumber.label')} />
        </Grid>
      </Grid>

      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm>
          <ControlledTextField name={`${addressKey}.city`} label={t('address.city.label')} required />
        </Grid>

        <Grid item xs={12} sm={2}>
          <ControlledTextField name={`${addressKey}.postalCode`} label={t('address.postalCode.label')} required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledSelect
            options={districtListSelect}
            name={`${addressKey}.district`}
            label={t('addDeliverySite.form.address.district.label')}
            required
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddressData
