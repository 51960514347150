import { Box, Paper, SxProps, Theme, Typography } from '@mui/material'

import { boxStyle, titleStyle } from './styles'

export interface ItemCardProps {
  title?: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const ItemCard = ({ title, children, sx = [] }: ItemCardProps) => {
  return (
    <Paper sx={[boxStyle, ...(Array.isArray(sx) ? sx : [sx])]}>
      {title && (
        <Typography variant="h5" fontWeight={700} sx={titleStyle}>
          {title}
        </Typography>
      )}
      <Box px={2} mt={title ? 0 : 2}>
        {children}
      </Box>
    </Paper>
  )
}
