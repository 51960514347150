/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from '@mui/material'
import { MainPaper, NoAuthLayout } from 'core/components'

interface TermsLayoutProps {
  title: React.ReactNode
  content: React.ReactNode
}

export const TermsLayout = ({ title, content }: TermsLayoutProps) => {
  return (
    <NoAuthLayout maxWidth="xl">
      <MainPaper sx={{ py: 6, px: 3 }}>
        <Typography variant="h1" mb={7} textAlign="center">
          {title}
        </Typography>
        <Typography component="div" sx={{ whiteSpace: 'pre-line', '& .subtitle': { typography: 'h4' } }}>
          <Typography
            component="div"
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{ '& h4': { typography: 'h4', fontWeight: 700, mb: -2, mt: 1 } }}
          />
        </Typography>
      </MainPaper>
    </NoAuthLayout>
  )
}
