import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import { MainPaper, MainTable, PaperTitle } from 'core/components'
import { Client } from 'core/types'

import { cellStyles, mapClientsColumns, mapClientsData } from './mapClientsTable'

interface ClientsTableProps {
  clients: Client[]
  onAddButtonClicked: () => void
  onEdit: (client: Client) => void
  onDelete: (client: Client) => void
  onCheckSpingo: (client: Client) => void
  isLoading: boolean
}

const ClientsTable = ({ clients, onAddButtonClicked, onEdit, onDelete, onCheckSpingo, isLoading }: ClientsTableProps) => {
  const { t } = useTranslation()

  return (
    <MainPaper>
      <PaperTitle
        title={t('clients.title')}
        rightEl={
          <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClicked}>
            {t('clients.add')}
          </Button>
        }
      />
      <MainTable
        columns={mapClientsColumns(t)}
        rows={mapClientsData(clients, t, { handleEdit: onEdit, handleDelete: onDelete, handleCheckSpingo: onCheckSpingo })}
        cellStyles={cellStyles}
        loading={isLoading}
      />
    </MainPaper>
  )
}

export default ClientsTable
