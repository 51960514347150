import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { DashboardWrapper } from 'client/pages/DashboardWrapper'
import Clients from 'companies/pages/Clients'
import { PageTitleBreadcrumbs } from 'core/components'
import { GeneralRoute, UserRole } from 'core/consts'
import Finanse from 'finanse/pages/Finanse'
import { TFunction } from 'i18next'
import InvoicesList from 'invoices/pages/InvoicesList'
import AdminCatalog from 'management/components/Catalog'
import { AdministratorDotykackaData } from 'management/components/DotykackaManagement'
import MinimalPriceRules from 'management/components/MinimalPriceRulesManagement'
import PaymentsManagement from 'management/components/PaymentsMenagement'
import RulesManagement from 'management/components/RulesManagement'
import UsersManagement from 'management/components/UsersManagement'
import AdminOrders from 'management/ordersManagement/AdminOrders'
import { AdminOrdersDetails } from 'management/ordersManagement/AdminOrdersDetails'
import { AdminProductDetails } from 'management/pages/AdminProductDetails'
import DotykackaClientIntegration from 'pos/components/DotykackaClientIntegration'
import { TermsOfSubscription, TermsOfUsage } from 'terms'
import { VendorCatalog } from 'vendor/components/VendorCatalog'
import { VendorConfiguration } from 'vendor/components/VendorManagement'
import VendorFinances from 'vendor/pages/VendorFinances'
import VendorOrders from 'vendor/pages/VendorOrders'
import VendorOrdersDetails from 'vendor/pages/VendorOrdersDetails'
import { VendorProductDetails } from 'vendor/pages/VendorProductDetails'

import { PlatformManagementTab, VendorManagementTab } from './routeTypes'

// Admin
const Calendar = lazy(() => import('admin/pages/Calendar'))
const Faq = lazy(() => import('admin/pages/Faq'))
const HelpCenter = lazy(() => import('admin/pages/HelpCenter'))
const Profile = lazy(() => import('admin/pages/Profile'))
const PlatformManagement = lazy(() => import('management/pages/PlatformManagement'))
const SingleVendorManagement = lazy(() => import('management/components/VendorsManagement/SingleVendorManagement'))
const VendorsManagement = lazy(() => import('management/components/VendorsManagement'))
const AddEditVendorMainData = lazy(() => import('management/components/VendorsManagement/vendorData/AddEditVendorMainData'))
const UploadCatalog = lazy(() => import('management/components/VendorsManagement/vendorData/UploadCatalog'))
const EditPaymentForClient = lazy(() => import('management/components/VendorsManagement/vendorData/EditPaymentForClient'))
const DisableDeliverySite = lazy(() => import('management/components/VendorsManagement/vendorData/DisableDeliverySite'))

// vendor
const VendorManagement = lazy(() => import('vendor/pages/VendorManagement'))

// Auth
const ForgotPassword = lazy(() => import('auth/pages/ForgotPassword'))
const ForgotPasswordSubmit = lazy(() => import('auth/pages/ForgotPasswordSubmit'))
const ActivateAccount = lazy(() => import('auth/pages/ActivateAccount'))
const Login = lazy(() => import('auth/pages/Login'))
const Register = lazy(() => import('auth/pages/Register'))
// Core
const Forbidden = lazy(() => import('core/pages/Forbidden'))
const NotFound = lazy(() => import('core/pages/NotFound'))
const UnderConstructions = lazy(() => import('core/pages/UnderConstructions'))

// Orders
const Basket = lazy(() => import('orders/pages/Basket'))
const UserOrders = lazy(() => import('users/pages/UserOrders'))
const UserOrderDetails = lazy(() => import('users/pages/UserOrderDetails'))

// Catalog
const Catalog = lazy(() => import('items/pages/Catalog'))

// OrdersWithoutLoggingIn
const AcceptOrders = lazy(() => import('orders/pages/AcceptOrder'))
const ProcessOrders = lazy(() => import('orders/pages/StartProcessing'))

//ShoppingList
const ShoppingListList = lazy(() => import('shoppingLists/pages/ShoppingListList'))
const ShoppingListDetails = lazy(() => import('shoppingLists/pages/ShoppingListDetails'))
const ShoppingListEdit = lazy(() => import('shoppingLists/pages/ShoppingListEdit'))
const LandingPage = lazy(() => import('landing/pages/LandingPage'))

export const privateRoutes = (t: TFunction) => [
  {
    path: '/home',
    element: <DashboardWrapper />,
    roles: [UserRole.CLIENT, UserRole.ADMIN, UserRole.VENDOR, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'client-finances',
    title: t('page.title.clientFinances'),
    element: <Finanse />,
  },
  {
    path: 'client-invoices',
    title: t('page.title.clientInvoices'),
    element: <InvoicesList />,
    roles: [UserRole.CLIENT],
  },
  {
    path: 'calendar',
    title: t('page.title.calendar'),
    element: <Calendar />,
  },
  {
    path: 'catalog',
    title: t('page.title.catalog'),
    element: <Catalog />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list',
    title: t('page.title.shoppingList'),
    element: <ShoppingListList />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list/:uuid',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.shoppingList', to: '/shopping-list' }, { title: 'page.title.shoppingListDetails' }]}
      />
    ),
    element: <ShoppingListDetails />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'shopping-list/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.shoppingList', to: '/shopping-list' }, { title: 'page.title.shoppingListEdit' }]}
      />
    ),
    element: <ShoppingListEdit />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'restaurants',
    title: t('page.title.restaurants'),
    element: <Clients />,
    roles: [UserRole.CLIENT],
  },
  {
    path: 'my-orders',
    title: t('page.title.myOrders'),
    element: <UserOrders />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'vendor-orders',
    title: t('page.title.vendorOrders'),
    element: <VendorOrders />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-catalog',
    title: t('page.title.vendorCatalog'),
    element: <VendorCatalog />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-catalog/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorCatalog', to: '/vendor-catalog' }, { title: 'page.title.vendorProductEdit' }]}
      />
    ),
    element: <VendorProductDetails />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-finances',
    title: t('page.title.vendorFinances'),
    element: <VendorFinances />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'my-orders/:uuid',
    title: <PageTitleBreadcrumbs items={[{ title: 'page.title.myOrders', to: '/my-orders' }, { title: 'page.title.orderDetails' }]} />,
    element: <UserOrderDetails />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    path: 'vendor-orders/:uuid',
    title: t('page.title.orderDetails'),
    element: <VendorOrdersDetails />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'users-management',
    title: t('page.title.usersManagement'),
    element: <UsersManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'vendor-management',
    title: t('page.title.vendorManagement'),
    element: <VendorsManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'vendor-management/add-vendor',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorManagement', to: '/vendor-management' }, { title: 'page.title.addVendorData' }]}
      />
    ),
    element: <AddEditVendorMainData />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'vendor-management/:uuid',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorManagement', to: '/vendor-management' }, { title: 'page.title.editVendorData' }]}
      />
    ),
    element: <SingleVendorManagement />,
    roles: [UserRole.ADMIN],
    subroutes: [
      {
        path: VendorManagementTab.ADD_VENDOR,
        title: 'vendors.addVendor',
        element: <AddEditVendorMainData />,
      },
      {
        path: VendorManagementTab.MAIN_DATA,
        title: 'vendors.mainData',
        element: <AddEditVendorMainData />,
      },
      {
        path: VendorManagementTab.CLIENT_PAY_METHODS,
        title: 'vendors.clientPaymentMethods',
        element: <EditPaymentForClient />,
      },
      {
        path: VendorManagementTab.DISABLE_DSITE,
        title: 'vendors.disableDSiteForVendor.title',
        element: <DisableDeliverySite />,
      },
      {
        path: VendorManagementTab.CATALOG_UPLOAD,
        title: 'vendors.catalogUpload.title',
        element: <UploadCatalog />,
      },
    ],
  },
  {
    path: 'payments-management',
    title: t('page.title.paymentsManagement'),
    element: <PaymentsManagement />,
    roles: [UserRole.ADMIN],
  },
  {
    path: '/dotykacka',
    title: t('page.title.dotykacka'),
    element: <AdministratorDotykackaData />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-catalog',
    title: t('page.title.catalog'),
    element: <AdminCatalog />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-catalog/:uuid/edit',
    title: (
      <PageTitleBreadcrumbs items={[{ title: 'page.title.catalog', to: '/admin-catalog' }, { title: 'page.title.adminProductEdit' }]} />
    ),
    element: <AdminProductDetails />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-orders',
    title: t('page.title.vendorOrders'),
    element: <AdminOrders />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'admin-orders/:uuid',
    title: t('page.title.orderDetails'),
    element: <AdminOrdersDetails />,
    roles: [UserRole.ADMIN],
  },
  {
    path: 'vendor-configuration',
    title: t('page.title.vendorConfiguration'),
    element: <VendorConfiguration />,
    roles: [UserRole.VENDOR],
  },
  {
    path: 'vendor-configuration/:uuid',
    title: (
      <PageTitleBreadcrumbs
        items={[{ title: 'page.title.vendorConfiguration', to: '/vendor-configuration' }, { title: 'page.title.editVendorData' }]}
      />
    ),
    element: <VendorManagement />,
    roles: [UserRole.VENDOR],
    subroutes: [
      {
        path: VendorManagementTab.MAIN_DATA,
        title: 'vendors.mainData',
        element: <AddEditVendorMainData />,
      },
    ],
  },
  {
    path: 'basket',
    title: t('page.title.basket'),
    element: <Basket />,
  },
  {
    path: 'faq',
    title: t('page.title.faq'),
    element: <Faq />,
  },
  {
    path: 'help',
    title: t('page.title.helpCenter'),
    element: <HelpCenter />,
  },
  {
    path: 'profile',
    title: t('page.title.profile'),
    element: <Profile />,
  },
  {
    path: 'platform-settings',
    title: t('page.title.platformSettings'),
    element: <PlatformManagement />,
    roles: [UserRole.ADMIN],
    //can not delete it, even though it is unnecessary
    subroutes: [
      {
        path: PlatformManagementTab.PRICE_RULES,
        title: 'platformManagement.rules.priceRules',
        element: <RulesManagement />,
      },
      {
        path: PlatformManagementTab.MINIMAL_PRICE,
        title: 'platformManagement.rules.priceRules',
        element: <MinimalPriceRules />,
      },
    ],
  },
]

export const routes = [
  {
    path: 'acceptOrder',
    element: <AcceptOrders />,
  },
  {
    path: 'startProcessing',
    element: <ProcessOrders />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'resetPassword',
    element: <ForgotPasswordSubmit />,
  },
  {
    path: 'activate',
    element: <ActivateAccount />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'under-construction',
    element: <UnderConstructions />,
  },
  {
    path: '403',
    element: <Forbidden />,
  },
  {
    path: '404',
    element: <NotFound />,
  },
  {
    path: 'integration-pos',
    element: <DotykackaClientIntegration />,
    roles: [UserRole.CLIENT],
  },
  {
    path: GeneralRoute.TERMS_OF_SUBSCRIPTION,
    element: <TermsOfSubscription />,
  },
  {
    path: GeneralRoute.TERMS_OF_USAGE,
    element: <TermsOfUsage />,
  },
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: GeneralRoute.LANDING_PAGE,
    element: <Navigate to="/" replace />,
  },

  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
]
