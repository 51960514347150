import MenuIcon from '@mui/icons-material/Menu'
import { Box, Container, IconButton, Toolbar, Typography } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { getUserRoles } from 'auth/contexts/AuthProvider'
import { NotificationCenter } from 'core/components/NotificationCenter'
import { drawerCollapsedWidth, drawerWidth } from 'core/config/layout'
import { UserRole } from 'core/consts'
import { useSettings } from 'core/contexts/SettingsProvider'
import BasketIconWithBadge from 'orders/components/BasketIconWithBadge'

type AppBarProps = {
  title: React.ReactNode
}

const AppBar = ({ title }: AppBarProps) => {
  const roles = getUserRoles()
  const { collapsed, toggleDrawer } = useSettings()
  const width = collapsed ? drawerCollapsedWidth : drawerWidth
  const canAccessCart = roles.some(x => x == UserRole.CLIENT || x == UserRole.RESTAURANT_MANAGER)

  return (
    <MuiAppBar
      color="default"
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${width}px)` },
        marginLeft: { lg: width },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ px: { xs: 0 } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              display: { lg: 'none' },
              mr: 2,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" component={typeof title === 'string' ? 'h1' : 'div'} sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <NotificationCenter />
          {canAccessCart && (
            <Box ml={2}>
              <BasketIconWithBadge />
            </Box>
          )}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}

export default AppBar
