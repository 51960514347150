import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, SvgIconProps, SxProps } from '@mui/material'

import { LabelWithIcon } from '../fields'
import { commonStyle } from './styles'

export interface FilterLabelProps {
  label: string
  icon: React.FC<SvgIconProps>
  onClear?: () => void
  sx?: SxProps
}

export const FilterLabel = ({ label, icon, onClear }: FilterLabelProps) => {
  return (
    <Box sx={[commonStyle, { py: typeof onClear === 'function' ? 1.4 : 1.7 }]}>
      <LabelWithIcon label={label} icon={icon} />
      {typeof onClear === 'function' && (
        <IconButton aria-label="clear" onClick={onClear} size="small" sx={{ p: 0.3 }}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  )
}
