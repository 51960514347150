import { memo } from 'react'

import { CheckCircle } from '@mui/icons-material'
import { Box, Paper, SxProps, Typography } from '@mui/material'

import { cardStyles, checkStyles } from './styles'

export interface RadioCardProps {
  selected: boolean
  title: React.ReactNode
  subtitle?: React.ReactNode
  icon?: React.ReactNode | JSX.Element
  onSelect: () => void
  sx?: SxProps
  disabled?: boolean
}

export const RadioCard = memo(function RadioCard({ selected, title, subtitle, icon, disabled, onSelect }: RadioCardProps) {
  return (
    <Paper
      sx={[
        cardStyles,
        {
          borderColor: selected ? 'primary.main' : 'grey.200',
          cursor: selected || disabled ? 'default' : 'pointer',
          bgcolor: selected ? 'background.default' : disabled ? 'grey.50' : 'background.paper',
          opacity: disabled ? 0.75 : 1,
          '&:hover': {
            borderColor: selected ? 'primary.main' : disabled ? 'grey.200' : 'grey.400',
            boxShadow: disabled || selected ? 1 : 2,
          },
        },
      ]}
      onClick={() => !selected && !disabled && onSelect()}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2} width="100%">
          {icon && icon}
          <Typography variant="body1" mt={0.5}>
            {title}
          </Typography>
        </Box>
        {subtitle && (
          <Typography variant="body2" component="div" color="text.secondary" mt={2}>
            {subtitle}
          </Typography>
        )}
        {selected && <CheckCircle color="primary" sx={checkStyles} />}
      </Box>
    </Paper>
  )
})
