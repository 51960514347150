import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Typography } from '@mui/material'
import { initialClientValues, validationClientSchema } from 'companies/helpers'
import {
  CodeVerificationContainer,
  ControlledCheckbox,
  ControlledTextField,
  ItemCard,
  TaxNumberControl,
  TitleWithName,
} from 'core/components'
import { GeneralRoute } from 'core/consts'
import { useCodeVerification } from 'core/hooks'
import { Client } from 'core/types'
import { Formik } from 'formik'

import AddressData from '../AddressData'

interface AddEditClientDialogProps {
  onClose: () => void
  onAction: (data: any) => void
  processing: boolean
  clients: Client[] | undefined
  client?: Client | null
}

const AddEditClientDialog = ({ onClose, onAction, processing, clients, client }: AddEditClientDialogProps) => {
  const { t } = useTranslation()
  const [isDuplicateTaxNumber, setIsDuplicateTaxNumber] = useState(false)
  const [clientName, setClientName] = useState(client?.name ?? '')

  const { codeVerificationOpen, loadingVerificationCode, generateVerificationCode, handleChangePhoneNo } = useCodeVerification({
    phoneNumberId: 'phoneNumber-input',
  })

  const onSubmit = async (values: any) => {
    const phoneNumber = values.phoneNumber?.toString()
    if (!codeVerificationOpen && client?.phoneNumber !== phoneNumber) {
      await generateVerificationCode(phoneNumber)
    } else {
      client ? onAction({ ...client, ...values, phoneNumber }) : onAction({ ...values, phoneNumber })
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth aria-labelledby="client-dialog-title">
      <DialogTitle id="client-dialog-title">
        {client ? <TitleWithName title="editClient.title" value={client?.name} /> : t('addClient.title')}
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={initialClientValues(client)} validationSchema={validationClientSchema(client, t)} onSubmit={onSubmit}>
        {(formik: any) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <ItemCard title={t('vendors.mainData')}>
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TaxNumberControl
                        data={clients}
                        initValues={initialClientValues(client)}
                        setIsDuplicateTaxNumber={setIsDuplicateTaxNumber}
                        setClientName={setClientName}
                        disabled={Boolean(client)}
                        addressKey="address"
                      />
                      {isDuplicateTaxNumber ? <Typography color="red">{t('addClient.duplicatedTaxNumber')}</Typography> : ''}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <ControlledTextField
                        disabled={Boolean(clientName) || Boolean(client)}
                        name="name"
                        label={t('addClient.form.name.label')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ControlledTextField name="contactEmail" label={t('addClient.form.contactEmail.label')} required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ControlledTextField
                        name="phoneNumber"
                        label={t('addClient.form.phoneNumber.label')}
                        type="phone"
                        required
                        helperText={
                          (client?.phoneNumber !== formik.values.phoneNumber?.toString() || !formik.values.phoneNumber?.toString()) &&
                          t('message.verificationCodeWillBeSendToPhoneNumber')
                        }
                      />
                    </Grid>
                  </Grid>
                </ItemCard>

                {/* Address */}
                <ItemCard title={t('address.title')}>
                  <AddressData addressKey={client ? 'invoicingAddress' : 'address'} />
                </ItemCard>

                {!client && (
                  <ControlledCheckbox
                    name="inheritDeliverySiteFromAddress"
                    label={t('addClient.form.inheritDeliverySiteFromAddress.label')}
                  />
                )}

                {!client && (
                  <ControlledCheckbox
                    name="termsOfUsageAccepted"
                    label={t('auth.register.form.termsOfUsageAccepted.label')}
                    required
                    expandedText={
                      <Trans
                        i18nKey="auth.register.form.termsOfUsageAccepted.expandedText"
                        components={{
                          linkTag: <Link href={`/${GeneralRoute.TERMS_OF_USAGE}`} target="_blank" />,
                        }}
                      />
                    }
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton
                  loading={processing || loadingVerificationCode}
                  type="submit"
                  variant="contained"
                  disabled={isDuplicateTaxNumber}
                >
                  {t(client ? 'editClient.save' : 'addClient.modal.add.action')}
                </LoadingButton>
              </DialogActions>
              {codeVerificationOpen && (
                <CodeVerificationContainer
                  onChangePhoneNo={handleChangePhoneNo}
                  onResendCode={generateVerificationCode}
                  phoneNumberFieldName="phoneNumber"
                />
              )}
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddEditClientDialog
