import { SxProps, Theme } from '@mui/material'

export const menuPaperStyle: SxProps<Theme> = {
  overflow: 'visible',
  border: 1,
  borderColor: 'grey.300',
  mt: 1.5,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  minWidth: '20rem',
  maxWidth: '32rem',
  '& .MuiList-root': {},
  '&::before': {
    borderTop: 1,
    borderLeft: 1,
    borderColor: 'grey.300',
    content: '""',
    display: { xs: 'none', md: 'block' },
    position: 'absolute',
    top: -1,
    right: 23,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

export const notificationStyle = {
  px: 2,
  py: 1.5,
  border: 1,
  borderColor: 'grey.200',
  borderRadius: 1,
  mt: 2,
  position: 'relative',
}
