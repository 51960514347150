import { CurrencyExchange, Description, ListAlt, MonetizationOn, ReceiptLong } from '@mui/icons-material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HomeIcon from '@mui/icons-material/Home'
import InventoryIcon from '@mui/icons-material/Inventory'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import { UserRole, UserRoute } from 'core/consts'
import { TFunction } from 'i18next'

export interface MenuRoutes {
  title: string
  to?: string
  expandKey?: string
  icon: any
  roles: UserRole[]
  subRoutes?: {
    title: string
    to: string
  }[]
}

export const menuRoutes = (t: TFunction): MenuRoutes[] => [
  {
    title: t('admin.drawer.menu.home'),
    to: UserRoute.HOME,
    icon: <HomeIcon />,
    roles: [UserRole.CLIENT, UserRole.ADMIN, UserRole.VENDOR, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('admin.drawer.menu.orders'),
    to: '/catalog',
    icon: <ShoppingBasketIcon />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('page.title.catalog'),
    to: '/admin-catalog',
    icon: <ShoppingBasketIcon />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.vendorOrders'),
    to: '/vendor-orders',
    icon: <ListAlt />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.vendorCatalog'),
    to: '/vendor-catalog',
    icon: <InventoryIcon />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.myOrders'),
    to: '/my-orders',
    icon: <ListAlt />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('admin.drawer.menu.shoppingLists'),
    to: '/shopping-list',
    icon: <ReceiptLong />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: `${t('admin.drawer.menu.finances')}`,
    to: '/vendor-finances',
    icon: <MonetizationOn />,
    roles: [UserRole.TEST_VENDOR],
  },
  {
    title: t('page.title.clientFinances'),
    to: '/client-finances',
    icon: <MonetizationOn />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('page.title.clientInvoices'),
    to: '/client-invoices',
    icon: <Description />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('admin.drawer.menu.restaurants'),
    to: '/restaurants',
    icon: <RestaurantIcon />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('admin.drawer.menu.vendorConfiguration'),
    to: '/vendor-configuration',
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.allOrders'),
    to: '/admin-orders',
    icon: <ListAlt />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.dotykacka'),
    to: '/dotykacka',
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.administration'),
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.ADMIN],
    expandKey: 'adminManagement',
    subRoutes: [
      {
        title: t('admin.drawer.menu.usersManagement'),
        to: '/users-management',
      },
      {
        title: t('admin.drawer.menu.vendorManagement'),
        to: '/vendor-management',
      },
      {
        title: t('admin.drawer.menu.paymentsManagement'),
        to: '/payments-management',
      },
    ],
  },
  {
    title: t('page.title.platformSettings'),
    to: '/platform-settings',
    icon: <CurrencyExchange />,
    roles: [UserRole.ADMIN],
  },
]
