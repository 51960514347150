import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { TFunction } from 'i18next'

export const adminPricingRulesSortOptions = (t: TFunction) => [
  {
    id: 'validFrom',
    name: t('platformManagement.sort.validFromAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-validFrom',
    name: t('platformManagement.sort.validFromDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'validTo',
    name: t('platformManagement.sort.validToAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-validTo',
    name: t('platformManagement.sort.validToDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'vendor.name',
    name: t('orders.vendorNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-vendor.name',
    name: t('orders.vendorNameDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'deliverySite.name',
    name: t('orders.deliverySiteNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-deliverySite.name',
    name: t('orders.deliverySiteNameDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'specialDiscount',
    name: t('platformManagement.sort.specialDiscountAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-specialDiscount',
    name: t('platformManagement.sort.specialDiscountDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'retroComission',
    name: t('platformManagement.sort.retroComissionAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-retroComission',
    name: t('platformManagement.sort.retroComissionDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'margin',
    name: t('platformManagement.sort.marginAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-margin',
    name: t('platformManagement.sort.marginDesc'),
    icon: <ArrowDownward />,
  },
]

export const adminMinimalPriceRulesSortOptions = (t: TFunction) => [
  {
    id: 'product.vendor.name',
    name: t('orders.vendorNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-product.vendor.name',
    name: t('orders.vendorNameDesc'),
    icon: <ArrowDownward />,
  },
  {
    id: 'product.name',
    name: t('platformManagement.sort.productNameAsc'),
    icon: <ArrowUpward />,
  },
  {
    id: '-product.name',
    name: t('platformManagement.sort.productNameDesc'),
    icon: <ArrowDownward />,
  },
]
