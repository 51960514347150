import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import ClientManagement from 'companies/components/ClientManagement'
import DeliverySiteManagement from 'companies/components/DeliverySiteManagement'
import { useClients } from 'companies/hooks'
import { NotReadyWrapper } from 'core/components'

const Clients = () => {
  const { t } = useTranslation()
  const { isLoadingClients, clients, isSuccess, isError, refetch: refetchClients } = useClients()

  return (
    <NotReadyWrapper
      isLoading={isLoadingClients}
      isSuccess={isSuccess}
      isError={isError}
      errorMsg={t('clients.errors.unexpected.subTitle')}
    >
      {clients && (
        <>
          <ClientManagement clients={clients} refetchClients={refetchClients} />
          <Box my={4}>
            <DeliverySiteManagement clients={clients} />
          </Box>
        </>
      )}
    </NotReadyWrapper>
  )
}

export default Clients
