import React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { initialDeliverySiteValues, validationDeliverySiteSchema } from 'companies/helpers'
import {
  AvatarImg,
  CodeVerificationContainer,
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextField,
  FileUpload,
  ItemCard,
  TitleWithName,
} from 'core/components'
import { useCodeVerification } from 'core/hooks'
import { Client, DeliverySite } from 'core/types'
import { prepareClients } from 'core/utils'
import { Formik } from 'formik'

import AddressData from '../AddressData'

type AddEditDeliverySiteDialogProps = {
  clients: Client[]
  onClose: () => void
  onAction: (data: any) => void
  processing: boolean
  deliverySite?: DeliverySite | null
}

const AddEditDeliverySiteDialog: React.FC<AddEditDeliverySiteDialogProps> = ({ clients, onClose, onAction, processing, deliverySite }) => {
  const { t } = useTranslation()

  const { codeVerificationOpen, loadingVerificationCode, generateVerificationCode, handleChangePhoneNo } = useCodeVerification({
    phoneNumberId: 'phoneNumber-input',
  })

  const onSubmit = async (values: any) => {
    const phoneNumber = values.phoneNumber?.toString()
    if (!codeVerificationOpen && deliverySite?.phoneNumber !== phoneNumber) {
      await generateVerificationCode(phoneNumber)
    } else {
      deliverySite ? onAction({ ...deliverySite, ...values, phoneNumber }) : onAction({ ...values, phoneNumber })
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle id="delivery-site-dialog-title">
        {deliverySite ? <TitleWithName title="editDeliverySite.title" value={deliverySite?.name} /> : t('addDeliverySite.title')}
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={initialDeliverySiteValues(deliverySite)}
        validationSchema={validationDeliverySiteSchema(deliverySite, t)}
        onSubmit={onSubmit}
      >
        {(formik: any) => {
          const handleFileChange = (file: File | null) => {
            formik.setFieldValue('deliverySiteLogo', file)
          }

          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <ItemCard title={t('vendors.mainData')}>
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>
                      <ControlledSelect
                        name="clientId"
                        options={prepareClients(clients, t, { noAll: true })}
                        label={t('addDeliverySite.form.client.label')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 4 }}>
                      <ControlledTextField name="name" label={t('addDeliverySite.form.name.label')} required />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        name="phoneNumber"
                        type="phone"
                        label={t('addClient.form.phoneNumber.label')}
                        required
                        helperText={
                          (deliverySite?.phoneNumber !== formik.values.phoneNumber?.toString() || !formik.values.phoneNumber?.toString()) &&
                          t('message.verificationCodeWillBeSendToPhoneNumber')
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box display="flex" gap={2}>
                    <FileUpload
                      name="deliverySiteLogo"
                      title={t('addDeliverySite.form.name.selectNewLogo')}
                      onFileChange={handleFileChange}
                      selectedFile={formik.values.deliverySiteLogo}
                    />
                    {!formik.values.deliverySiteLogo && deliverySite && deliverySite?.deliverySiteLogoUrl && (
                      <AvatarImg imgUrl={deliverySite.deliverySiteLogoUrl} name={deliverySite.name} sx={{ width: 56, height: 56 }} />
                    )}
                  </Box>
                </ItemCard>

                {/* Address */}
                <ItemCard title={t('address.title')}>
                  {!deliverySite && (
                    <ControlledCheckbox
                      name="inheritAddressFromClient"
                      label={t('addDeliverySite.form.inheritAddressFromClient.label')}
                      onChange={() => formik.setFieldValue('inheritAddressFromClient', !formik.values.inheritAddressFromClient)}
                      sx={{ my: 3 }}
                    />
                  )}
                  {!formik.values.inheritAddressFromClient && <AddressData addressKey="address" />}
                </ItemCard>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton loading={processing || loadingVerificationCode} type="submit" variant="contained">
                  {t('editDeliverySite.save')}
                </LoadingButton>
              </DialogActions>
              {codeVerificationOpen && (
                <CodeVerificationContainer
                  onChangePhoneNo={handleChangePhoneNo}
                  onResendCode={generateVerificationCode}
                  phoneNumberFieldName="phoneNumber"
                />
              )}
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddEditDeliverySiteDialog
