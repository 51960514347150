import { PHONE_REGEXP_PL } from 'core/consts'
import { Address, Client, DeliverySite } from 'core/types'
import { TFunction } from 'i18next'
import * as Yup from 'yup'

export const initialClientValues = (client: Client | null | undefined) =>
  client
    ? {
        name: client.name,
        taxNumber: client.taxNumber,
        phoneNumber: client.phoneNumber,
        contactEmail: client?.contactEmail || '',
        invoicingAddress: client.invoicingAddress as Address,
      }
    : {
        name: '',
        taxNumber: '',
        phoneNumber: null,
        address: {
          street: '',
          streetNumber: '',
          apartmentNumber: '',
          city: '',
          district: '',
          postalCode: '',
        } as Address,
        inheritDeliverySiteFromAddress: false,
        contactEmail: '',
        termsOfUsageAccepted: false,
      }

export const validationClientSchema = (client: Client | null | undefined, t: TFunction) =>
  client
    ? Yup.object({
        name: Yup.string().required(t('common.validations.required')),
        taxNumber: Yup.string().required(t('common.validations.required')),
        phoneNumber: Yup.string()
          .matches(PHONE_REGEXP_PL, t('common.validations.phoneNumber.invalid'))
          .required(t('common.validations.required')),
        invoicingAddress: Yup.object({
          street: Yup.string().required(t('common.validations.required')),
          streetNumber: Yup.string().required(t('common.validations.required')),
          apartmentNumber: Yup.string(),
          city: Yup.string().required(t('common.validations.required')),
          district: Yup.string().required(t('common.validations.required')),
          postalCode: Yup.string().required(t('common.validations.required')),
        }),
        contactEmail: Yup.string().email(t('addClient.emailValidations')).required(t('common.validations.required')),
        isActive: Yup.boolean(),
      })
    : Yup.object().shape({
        name: Yup.string().required(t('common.validations.required')),
        taxNumber: Yup.string()
          .required(t('common.validations.required'))
          .matches(/^[0-9]{10}$/, t('addClient.taxNumberValidations')),
        phoneNumber: Yup.string()
          .matches(PHONE_REGEXP_PL, t('common.validations.phoneNumber.invalid'))
          .required(t('common.validations.required')),
        address: Yup.object().shape({
          street: Yup.string().required(t('common.validations.required')),
          streetNumber: Yup.string().required(t('common.validations.required')),
          apartmentNumber: Yup.string(),
          city: Yup.string().required(t('common.validations.required')),
          district: Yup.string().required(t('common.validations.required')),
          postalCode: Yup.string().required(t('common.validations.required')),
        }),
        inheritDeliverySiteFromAddress: Yup.boolean(),
        contactEmail: Yup.string().email(t('addClient.emailValidations')).required(t('common.validations.required')),
        termsOfUsageAccepted: Yup.bool().oneOf([true], t('common.validations.required')),
      })

export const initialDeliverySiteValues = (deliverySite: DeliverySite | null | undefined) =>
  deliverySite
    ? {
        name: deliverySite.name,
        clientId: deliverySite?.responsibleClientId || '',
        phoneNumber: deliverySite.phoneNumber,
        deliverySiteLogo: null,
        address: deliverySite.deliveryAddress as Address,
      }
    : {
        name: '',
        clientId: '',
        inheritAddressFromClient: false,
        phoneNumber: '',
        deliverySiteLogo: null,
        address: {
          street: '',
          streetNumber: '',
          apartmentNumber: '',
          city: '',
          district: '',
          postalCode: '',
        } as Address,
      }

export const validationDeliverySiteSchema = (deliverySite: DeliverySite | null | undefined, t: TFunction) =>
  deliverySite
    ? Yup.object({
        name: Yup.string().required(t('common.validations.required')),
        clientId: Yup.string().required(t('common.validations.required')),
        phoneNumber: Yup.string()
          .matches(PHONE_REGEXP_PL, t('common.validations.phoneNumber.invalid'))
          .required(t('common.validations.required')),
        address: Yup.object({
          street: Yup.string().required(t('common.validations.required')),
          streetNumber: Yup.string().required(t('common.validations.required')),
          apartmentNumber: Yup.string(),
          city: Yup.string().required(t('common.validations.required')),
          district: Yup.string().required(t('common.validations.required')),
          postalCode: Yup.string().required(t('common.validations.required')),
        }),
      })
    : Yup.object().shape({
        name: Yup.string().required(t('common.validations.required')),
        clientId: Yup.string().required(t('common.validations.required')),
        phoneNumber: Yup.string()
          .matches(PHONE_REGEXP_PL, t('common.validations.phoneNumber.invalid'))
          .required(t('common.validations.required')),
        inheritAddressFromClient: Yup.boolean(),
        address: Yup.object()
          .shape({
            street: Yup.string(),
            streetNumber: Yup.string(),
            apartmentNumber: Yup.string(),
            city: Yup.string(),
            district: Yup.string(),
            postalCode: Yup.string(),
          })
          .test('is-required', t('common.validations.required'), function (value) {
            const { inheritAddressFromClient } = this.parent
            if (!inheritAddressFromClient) {
              return addressSchema(t).isValidSync(value)
            }
            return true
          }),
      })

const addressSchema = (t: TFunction) =>
  Yup.object({
    street: Yup.string().required(t('common.validations.required')),
    streetNumber: Yup.string().required(t('common.validations.required')),
    apartmentNumber: Yup.string(),
    city: Yup.string().required(t('common.validations.required')),
    district: Yup.string().required(t('common.validations.required')),
    postalCode: Yup.string().required(t('common.validations.required')),
  })
