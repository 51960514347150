import { SxProps, Theme, darken, lighten } from '@mui/material'

export const menuItemStyle: SxProps<Theme> = {
  color: 'text.primary',
  borderRadius: 1,
  ':hover': {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.7) : lighten(theme.palette.secondary.main, 0.8),
  },
  '&.active': {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark' ? darken(theme.palette.primary.main, 0.5) : lighten(theme.palette.secondary.main, 0.5),
  },
}
