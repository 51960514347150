import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import SettingsIcon from '@mui/icons-material/Settings'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { Footer, SettingsDrawer } from 'core/components'
import Logo from 'core/components/Logo'
import { UserRoute } from 'core/consts'

import { containerStyle } from './styles'

interface NoAuthLayoutProps {
  children: React.ReactNode
  maxWidth?: 'sm' | 'xl'
  noLogo?: boolean
}

export const NoAuthLayout = ({ maxWidth = 'sm', noLogo = false, children }: NoAuthLayoutProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false)

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen)
  }

  return (
    <Box>
      <AppBar
        color="inherit"
        sx={{ bgcolor: noLogo ? 'inherit' : 'background.default', borderBottom: noLogo ? 0 : 1, borderColor: 'grey.300' }}
        position="fixed"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              {!noLogo && (
                <Box sx={{ display: 'flex' }}>
                  <NavLink to={UserRoute.HOME}>
                    <Logo size={100} />
                  </NavLink>
                </Box>
              )}
            </Box>
            <IconButton aria-label="settings" component="span" onClick={handleSettingsToggle}>
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <SettingsDrawer onDrawerToggle={handleSettingsToggle} open={settingsOpen} />
      <Box sx={containerStyle}>
        <Container
          component="main"
          maxWidth={maxWidth}
          sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 6 }}
        >
          {children}
        </Container>
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </Box>
    </Box>
  )
}
