import React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { TitleWithName } from 'core/components'
import { Client } from 'core/types'

interface ConfirmDeleteClientDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (clientId: string) => void
  client: Client | null
}

const ConfirmDeleteClientDialog: React.FC<ConfirmDeleteClientDialogProps> = ({ open, onClose, onConfirm, client }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <TitleWithName title="confirmDeleteClientDialog.title" value={client?.name} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('confirmDeleteClientDialog.message')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />}>
          {t('confirmDeleteClientDialog.cancel')}
        </Button>
        <Button onClick={() => client && onConfirm(client.id)} variant="contained" color="error">
          {t('confirmDeleteClientDialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDeleteClientDialog
