import { Fragment, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'

import { Notifications } from '@mui/icons-material'
import { Badge, Box, Button, Fab, Menu, Tooltip, Typography } from '@mui/material'
import { useNotifications } from 'core/contexts'
import { NotificationEvent } from 'core/types'
import dayjs from 'dayjs'

import SingleNotification from './SingleNotification'
import { useReadAllNotifications, useReadNotification } from './hooks'
import { menuPaperStyle } from './styles'

export const NotificationCenter = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [,] = useTransition()
  const { eventsData, setEventsData } = useNotifications()

  const { mutateAsync: callReadNotification } = useReadNotification()
  const { mutateAsync: callReadAllNotifications } = useReadAllNotifications()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onReadAll = async () => {
    let initialState: NotificationEvent[] = []
    setEventsData(prev => {
      initialState = [...prev]
      return prev.map(el => ({ ...el, read: true }))
    })

    try {
      await callReadAllNotifications()
    } catch {
      setEventsData(prev =>
        prev.map(el => {
          const cached = initialState.find(x => x.id == el.id)
          if (cached) {
            return { ...el, read: cached.read }
          }
          return el
        }),
      )
    }
  }

  const onRead = async (id: string) => {
    setEventsData(prev =>
      prev.map(el => {
        if (el.id === id) {
          return { ...el, read: true }
        } else {
          return el
        }
      }),
    )

    try {
      await callReadNotification({ id: id })
    } catch {
      setEventsData(prev =>
        prev.map(el => {
          if (el.id === id) {
            return { ...el, read: false }
          } else {
            return el
          }
        }),
      )
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* Notifications icon button */}
        <Tooltip title={t('notification.notifications')}>
          <Fab
            color="inherit"
            onClick={handleClick}
            aria-controls={open ? 'notifications-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={eventsData.filter(el => !el.read).length} color="error">
              <Notifications />
            </Badge>
          </Fab>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: menuPaperStyle,
        }}
        MenuListProps={{
          sx: { maxHeight: '30rem', overflowY: 'auto' },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box px={3} pt={4} pb={2}>
          {eventsData?.length > 0 ? (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                <Typography variant="h4" mb={0.5}>
                  {t('notification.notifications')}
                </Typography>
                {eventsData.some(el => !el.read) && (
                  <Button variant="outlined" onClick={onReadAll} size="small">
                    {t('common.action.markAllAsRead')}
                  </Button>
                )}
              </Box>
              {eventsData.toReversed().map(el => (
                <Fragment key={dayjs(el.timestamp).toISOString()}>
                  <SingleNotification {...el} onRead={onRead} onClose={handleClose} />
                </Fragment>
              ))}
            </>
          ) : (
            <Box>
              <Typography variant="h5" mb={1}>
                {t('notification.noNewNotifications')}
              </Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  )
}
