import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import SettingsIcon from '@mui/icons-material/Settings'
import { ListItem, ListItemButton, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { Footer, SettingsDrawer } from 'core/components'
import Logo from 'core/components/Logo'

import { MobileMenu } from './MobileMenu'
import { menuItemStyle } from './styles'

interface LandingLayoutProps {
  children: React.ReactNode
}

export const LandingLayout = ({ children }: LandingLayoutProps) => {
  const { t } = useTranslation()
  const [settingsOpen, setSettingsOpen] = useState(false)

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen)
  }

  const navItems = [
    {
      title: t('home.login.title'),
      to: '/login',
    },
    // {
    //   title: t('home.about.title'),
    //   to: '/about-us',
    // },
    // {
    //   title: t('home.contact.title'),
    //   to: '/contact',
    // }, commented until we make pages
  ]

  const footer = (
    <Container maxWidth="xl">
      <Footer />
    </Container>
  )

  return (
    <Box bgcolor="common.white">
      <AppBar color="transparent" position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex' }}>
                <NavLink to="/">
                  <Logo size={100} />
                </NavLink>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1, mr: 4 }}>
              {navItems.map(el => (
                <ListItem key={el.title} component={NavLink} to={el.to ?? ''} disablePadding>
                  <ListItemButton sx={[menuItemStyle, { textAlign: 'center', textWrap: 'nowrap' }]}>
                    <Typography variant="h5" component="span">
                      {el.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <IconButton aria-label="settings" component="span" onClick={handleSettingsToggle} sx={{ mr: 4 }}>
              <SettingsIcon />
            </IconButton>
            <MobileMenu items={navItems} />
          </Toolbar>
        </Container>
      </AppBar>

      <SettingsDrawer onDrawerToggle={handleSettingsToggle} open={settingsOpen} />
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {children}
        <Box sx={{ mt: -0.0725, zIndex: 13 }}>{footer}</Box>
      </Box>
    </Box>
  )
}
