import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Typography } from '@mui/material'
import { MainFilters, MainTable } from 'core/components'
import MainPaper from 'core/components/MainPaper'
import { FilterName, Item } from 'core/types'
import { useCatalog } from 'management/hooks'
import { catalogSortOptions } from 'orders/helpers'
import { mapVendorCatalog, mapVendorCatalogColumns, vendorCatalogCellStyles } from 'vendor/components/VendorCatalog/mapVendorCatalog'

import { FiltersState, filtersStateInit, useCatalogFilters } from './useCatalogFilters'

const Catalog = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')

  const sortOptions = useMemo(() => catalogSortOptions(t), [t])
  const [sort, setSort] = useState(sortOptions.find(el => el.id === 'name')?.id ?? sortOptions[0].id)

  const [filtersState, setFiltersState] = useState<FiltersState>(filtersStateInit)

  const {
    data: paginatedResult,
    isFetching,
    isError,
  } = useCatalog({
    Page: currentPage + 1,
    PageSize: pageSize,
    Filters: searchTerm,
    VendorId: filtersState[FilterName.ALL_VENDORS],
    Sorts: sort,
  })

  const { filters } = useCatalogFilters()

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const handleSearch = useCallback((search: string) => {
    setSearchTerm(`search@=*${search}`)
    setCurrentPage(0)
  }, [])

  const onSetFiltersState = useCallback((data: any) => {
    setCurrentPage(0)
    setFiltersState(data)
  }, [])

  const onSetSort = useCallback((data: string) => {
    setSort(data)
  }, [])

  const clearAllFilters = useCallback(() => {
    setCurrentPage(0)
    setFiltersState(filtersStateInit)
  }, [])

  const handleEdit = useCallback((item: Item) => {
    navigate(`/admin-catalog/${item.id}/edit`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MainFilters
        filters={filters}
        handleSearch={handleSearch}
        sort={sort}
        setSort={onSetSort}
        sortOptions={sortOptions}
        filtersState={filtersState}
        setFiltersState={onSetFiltersState}
        clearAllFilters={clearAllFilters}
      />
      <MainPaper sx={{ position: 'relative' }}>
        {isError ? (
          <Typography color="error" sx={{ whiteSpace: 'pre-line' }}>
            {t('common.errors.unexpected.subTitle')}
          </Typography>
        ) : (
          <MainTable
            columns={mapVendorCatalogColumns(t)}
            rows={mapVendorCatalog(paginatedResult ?? { totalCount: 0, items: [] }, { handleEdit }, t)}
            count={paginatedResult?.totalCount}
            pagination={{
              page: currentPage,
              onPageChange: handlePageChange,
              pageSize,
              onPageSizeChange: handlePageSizeChange,
            }}
            loading={isFetching}
            cellStyles={vendorCatalogCellStyles}
          />
        )}
      </MainPaper>
    </>
  )
}

export default Catalog
