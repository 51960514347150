import apiEndpoints from 'api/endpoints'
import { useRequest } from 'core/hooks'
import { PaymentLimit } from 'core/types'

export const useGetSpingoLimit = (clientId: string, enabled: boolean) => {
  const { data, isError, isFetching, ...other } = useRequest<PaymentLimit>(apiEndpoints.getSpingoLimit(clientId), {
    enabled,
  })

  return { spingoLimitData: data, spingoLimitIsError: isError, spingoLimitIsFetching: isFetching, ...other }
}
