import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, TextField as MuiTextField, Typography } from '@mui/material'

import ButtonLink from '../ButtonLink'
import PhoneNumber from '../PhoneNumber'
import { commonInputStyle } from './styles'

export interface CodeVerificationProps {
  onResendCode: (phoneNo: string) => void
  onSubmitCode: (code: string) => void
  onChangePhoneNo: () => void
  phoneNo: string
  errorMsg?: string
  loading?: boolean
}

const CodeVerification = ({ phoneNo, onChangePhoneNo, onResendCode, onSubmitCode, errorMsg, loading }: CodeVerificationProps) => {
  const { t } = useTranslation()
  const [code, setCode] = useState(['', '', '', '', '', ''])
  const inputsRef = useRef<HTMLInputElement[]>([])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const value = event.target.value
    if (/^[0-9]$/.test(value)) {
      // Allow only digits
      const newCode = [...code]
      newCode[idx] = value
      setCode(newCode)

      // Move focus to the first empty field
      const firstEmptyIndex = newCode.findIndex(digit => digit === '')
      if (firstEmptyIndex !== -1) {
        inputsRef.current[firstEmptyIndex].focus()
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent, idx: number) => {
    if (event.key === 'Backspace') {
      const newCode = [...code]
      if (newCode[idx]) {
        newCode[idx] = ''
        setCode(newCode)
      } else if (idx > 0) {
        newCode[idx - 1] = ''
        setCode(newCode)
        inputsRef.current[idx - 1].focus()
      }
    } else if (event.key === 'Enter') {
      event.preventDefault()
      if (code.indexOf('') === -1) {
        handleSendCode()
      }
    }
  }

  const handleSendCode = () => {
    const verificationCode = code.join('')
    onSubmitCode(verificationCode)
  }

  return (
    <Dialog open>
      <Box display="flex" flexDirection="column" alignItems="center">
        <DialogTitle align="center" component="div">
          <Trans
            i18nKey="message.enterVerificationCode"
            components={{
              phoneNoTag: <PhoneNumber phoneCode="+48" phoneNo={phoneNo} />,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box component="form" display="flex" flexDirection="column" alignItems="center">
            <Grid container justifyContent="center">
              {code.map((digit, idx) => (
                <MuiTextField
                  key={idx}
                  id={`smsCode-${idx}`}
                  value={digit}
                  onChange={e => handleChange(e, idx)}
                  onKeyDown={e => handleKeyDown(e, idx)}
                  variant="outlined"
                  type="number"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center', fontSize: '1.8rem' },
                  }}
                  inputRef={el => (inputsRef.current[idx] = el)}
                  sx={commonInputStyle}
                  autoFocus={idx === 0}
                />
              ))}
            </Grid>
            {errorMsg && (
              <FormHelperText sx={{ mb: 0.8, textAlign: 'center' }} error>
                <Typography variant="caption">{errorMsg}</Typography>
              </FormHelperText>
            )}
            <LoadingButton
              variant="contained"
              disabled={code.indexOf('') !== -1}
              onClick={handleSendCode}
              sx={{ minWidth: '50%', mt: 2 }}
              loading={loading}
            >
              {t('common.action.verify')}
            </LoadingButton>

            <ButtonLink
              title={t('message.didntGetSms')}
              btnTitle={t('common.action.resendCode')}
              disabled={loading}
              fnCallback={() => onResendCode(phoneNo)}
              sx={{ mt: 3, mb: 1 }}
            />
            <ButtonLink
              title={t('message.isPhoneIncorrect')}
              btnTitle={t('common.action.changePhone')}
              disabled={loading}
              fnCallback={onChangePhoneNo}
            />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default CodeVerification
