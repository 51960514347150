import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'

import { MainLayout } from 'core/components'
import Loader from 'core/components/Loader'
import dayjs from 'dayjs'

import { privateRoutes, routes } from './config/routes'
import PrivateRoute from './core/components/PrivateRoute'

const AppRoutes = () => {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <Routes>
      {privateRoutes(t).map(el => (
        <Route
          key={el.path}
          path={el.path}
          element={
            <PrivateRoute roles={el.roles}>
              <MainLayout title={el.title}>
                <Suspense fallback={<Loader />}>{el.element}</Suspense>
              </MainLayout>
            </PrivateRoute>
          }
        >
          {el.subroutes && el.subroutes.map(subroute => <Route key={subroute.path} path={subroute.path} element={subroute.element} />)}
        </Route>
      ))}
      {routes.map(el => (
        <Route key={el.path} path={el.path} element={<Suspense fallback={<Loader />}>{el.element}</Suspense>} />
      ))}
    </Routes>
  )
}

export default AppRoutes
