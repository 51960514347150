import { Link as RouterLink } from 'react-router-dom'

import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import { Box, Button, Chip, Link, Tooltip } from '@mui/material'
import { PaymentTypeVal } from 'core/consts'
import { AdminOrderItem, PaginatedResult } from 'core/types'
import { statusDisplayProperties } from 'core/types/statusDisplayProperties'
import { formatDateAndTime, formatPrice, getPaymentMethodIcon } from 'core/utils'
import { TFunction } from 'i18next'

export const mapAdminOrdersData = (data: PaginatedResult<AdminOrderItem>, t: TFunction) => {
  const mappedData = data.items.map(el => {
    const { color, messageKey } = statusDisplayProperties[el.status] || { color: 'black', messageKey: 'order.status.unknown' }

    const isOnlinePayment = el.paymentMethod === PaymentTypeVal.OnlinePayment || el.paymentMethod === PaymentTypeVal.Spingo

    return {
      rowId: el.id,
      data: {
        friendlyOrderNumber: (
          <Link component={RouterLink} to={`/admin-orders/${el.id}`}>
            {el.friendlyOrderNumber}
          </Link>
        ),
        vendorName: el.counterparty,
        deliverySiteName: el.deliverySiteName,
        date: (
          <Tooltip title={formatDateAndTime(el.orderDate, true)}>
            <span>{formatDateAndTime(el.orderDate)}</span>
          </Tooltip>
        ),
        sum: formatPrice(isOnlinePayment ? el.skaloTotalCost : el.totalCost),
        status: <Chip label={t(messageKey)} sx={{ bgcolor: color, color: 'common.white' }} />,
        paymentMethod: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {getPaymentMethodIcon(el.paymentMethod)}
            {t(`payment.paymentsMethodVal.${el.paymentMethod}`)}
          </Box>
        ),
        details: (
          <Button component={RouterLink} to={`/admin-orders/${el.id}`} endIcon={<KeyboardDoubleArrowRight />}>
            {t('userOrders.details')}
          </Button>
        ),
      },
    }
  })

  return mappedData
}

export const mapAdminOrdersColumns = (t: TFunction) => [
  { title: t('userOrders.order'), id: 'friendlyOrderNumber' },
  { title: t('userOrders.vendor'), id: 'vendorName' },
  { title: t('userOrders.restaurant'), id: 'deliverySiteName' },
  { title: t('userOrders.orderDate'), id: 'orderDate' },
  { title: t('userOrders.sum'), id: 'sum' },
  { title: t('userOrders.status'), id: 'status' },
  { title: t('userOrders.paymentMethod'), id: 'paymentMethod' },
  { title: '', id: 'details' },
]
export const cellStyles = [
  {
    id: 'deliverySiteName',
    styles: {
      fontWeight: 700,
      color: 'primary.main',
    },
  },
]
