import { UploadCatalogColumnConfig } from 'core/types'

export const mapCatalogConfig = (data: UploadCatalogColumnConfig) => {
  const fields = {
    'ColumnConfig.ContainsHeader': JSON.stringify(data.containsHeader),
    'ColumnConfig.ProductNameColumn': data.productNameColumn,
    'ColumnConfig.NetPriceColumn': data.netPriceColumn,
    'ColumnConfig.VatPercentColumn': data.vatPercentColumn ?? '',
    'ColumnConfig.VatConstValue': data.vatConstValue ?? '',
    'ColumnConfig.VendorCodeColumn': data.vendorCodeColumn ?? '',
    'ColumnConfig.MinCartAmountColumn': data.minCartAmountColumn ?? '',
  }
  return fields
}
