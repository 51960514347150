import { SxProps, Theme } from '@mui/material'

export const containerStyle: SxProps<Theme> = {
  width: '100%',
  minHeight: 'calc(100vh - 81px - 32px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  mt: 13,
}
